export const B2BRewardsSpec = `openapi: 3.0.3
info:
  title: "Reward Engine"
  description: "Simple and Fast Reward Engine"
  version: "1.2"
paths:
  /v1/application:
    post:
      tags:
        - Application
      summary: Create new application
      description: Creates Rewards Engine Application for a Country

      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              siteId: 2,
              country: "India"
              timezone: "Asia/Kolkata"
              pointsMultiplierRuleSet: {
                    rules: [{
                        "name":"Order Channel",
                        "matchWith": ["online"],
                        "multiplier": 2
                    }, {
                        "name":"Order Channel",
                        "matchWith": ["offline"],
                        "multiplier": 1.5
                    }]
                }
              
      responses:
        "200":
          description: Successful operation
          content:
            application/json:
              examples:
                success:
                  value:
                    data:
                      id: 2
                      country: India
                      siteId: "5"
                      timezone: Asia/Kolkata
                    success: true
                    message: Success
                faliure:
                  value:
                    success: false
                    message: Application already exists

  /v1/users:
    post:
      tags:
        - Reward Engine User
      summary: "Create Reward Engine User"
      description: "Creates reward engine user for given store id and app-id "

      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              storeId: "100012"
              applicationId: 1
              email: user@domain.com
              phone: "83237832738232378"
              
      responses:
        "200":
          $ref: "#/components/responses/RewardEngineUserResponse"

    get:
      parameters:
        - name: id
          in: query
          required: false
          description: User ID of the user to retrieve
          schema:
            type: integer
        - name: storeId
          in: query
          required: false
          description: Store ID of the user to retrieve
          schema:
            type: string
        - name: page
          in: query
          required: true
          description: page number of the paginated pages
          schema:
            type: integer
        - name: limit
          in: query
          required: true
          description: limit size of the pages
          schema:
            type: integer

      tags:
        - Reward Engine User
      summary: "Get User Details"
      description: ""
      responses:
        "200":
          $ref: "#/components/responses/GETRewardEngineUserResponse"
  /v1/users/{storeId}:
    put:
      parameters:
        - name: storeId
          in: path
          required: true
          description: ID of the user to update
          schema:
            type: integer
        - name: applicationId
          in: header
          required: true
          description: Application id where this user exsists
          schema:
            type: integer
      tags:
        - Reward Engine User
      summary: "Update User Details"
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              storeId: "1411"
              applicationId: 1
              phone: "8198191181"
              email: abc5as@localhost.com
              totalPoints: "500.0"

      description: ""
      responses:
        "200":
          $ref: "#/components/responses/RewardEngineUserResponse"

  /v1/activities:
    post:
      tags:
        - "Activities"
      summary: "Create new activity"
      description: ""
      parameters: []
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              name: Watch Video
              applicationId: 1
              slug: "act:purchase:001"
              points: 15
              maxLimitPerUser: 5
              limitFrequency: 1
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  id: 7
                  applicationId: 1
                  name: Watch Video
                  points: 15
                  maxLimitPerUser: 5
                  limitFrequency: 1
                  conversionRate: 0
                  amountPerUnit: 0
                  pointsAwardedPerUnit: 0
                  createdAt: "2024-07-12T06:00:13.315449057Z"
                  updatedAt: "2024-07-12T06:00:13.315457734Z"
                success: true
                message: Success
    get:
      parameters:
        - name: id
          in: query
          required: false
          description: User ID of the user to retrieve
          schema:
            type: integer
        - name: applicationId
          in: query
          required: false
          description: User ID of the user to retrieve
          schema:
            type: integer
        - name: page
          in: query
          required: true
          description: page number of the paginated pages
          schema:
            type: integer
        - name: limit
          in: query
          required: true
          description: limit size of the pages
          schema:
            type: integer

      tags:
        - Activities
      summary: "Get Activity Config List"
      description: ""
      responses:
        "200":
          description: "success response"
          content:
            application/json:
              example:
                data:
                  data:
                    - id: 1
                      applicationId: 1
                      name: Login
                      points: 15
                      maxLimitPerUser: 5
                      limitFrequency: 1
                      conversionRate: 0
                      amountPerUnit: 0
                      pointsAwardedPerUnit: 0
                      createdAt: "2024-07-03T05:44:17.521151Z"
                      updatedAt: "2024-07-03T05:44:17.52116Z"
                  total_pages: 1
                  total_items: 1
                success: true
                message: Success

  /v1/activities/{id}:
    put:
      tags:
        - Activities
      summary: "Update activity config"
      description: ""
      parameters:
        - name: id
          in: path
          required: true
          description: userId for activity that will be updated
          schema:
            type: integer
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              points: 15
              maxLimitPerUser: 5
              limitFrequency: 1
              amountPerUnit: 40
              pointsAwardedPerUnit: 25
      responses:
        "200":
          description: "success response"
          content:
            application/json:
              example:
                data:
                  id: 7
                  applicationId: 1
                  name: Points Transfer
                  points: 15
                  maxLimitPerUser: 5
                  limitFrequency: 1
                  conversionRate: 0
                  amountPerUnit: 0
                  pointsAwardedPerUnit: 0
                  createdAt: "2024-07-12T06:00:13.315449057Z"
                  updatedAt: "2024-07-12T06:00:13.315457734Z"
                success: true
                message: Success

  /v1/users/{storeId}/activities:
    post:
      tags:
        - Reward Engine User
      summary: "Perform/create new activity"
      description: ""
      parameters:
        - name: storeId
          in: path
          required: true
          description: userid for which activities are getting fetched
          schema:
            type: integer
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              applicationId: 1
              slug: "act:points-transfer:001"
              data: {
                transferTo: "123",
                transferPoints: 100
              }
      responses:
        "200":
          description: "success response"
          content:
            application/json:
              example:
                data:
                  PointsCredited: 15
                  UpdatedPointsBalance: 34
                success: true
                message: Success

  /v1/users/activities:
    get:
      tags:
        - Reward Engine User
      summary: "List user activity history"
      description: ""
      parameters:
        - name: to
          in: query
          required: false
          description: max date to filter records
          schema:
            type: string
        - name: from
          in: query
          required: false
          description: min date to filter records
          schema:
            type: string
        - name: activityType
          in: query
          required: false
          description: type of activity to filter records
          schema:
            type: string
        - name: storeId
          in: query
          required: false
          description: given store id to filter records
          schema:
            type: string
        - name: order
          in: query
          required: false
          description: sorting order
          schema:
            type: string
        - name: page
          in: query
          required: false
          description: page number of the paginated pages
          schema:
            type: integer
        - name: limit
          in: query
          required: false
          description: limit size of the pages
          schema:
            type: integer
      responses:
        "200":
          $ref: "#/components/responses/UserActivityListingResponse"

  /v1/goals:
    post:
      tags:
        - "Goals"
      summary: "Create New Goal"
      description: ""
      parameters: []
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              applicationId: 1
              userGoalsFileUrl: http://localhost/sales-target.csv
              name: Sales Target 1
              ruleSet:
                rules:
                  - includedCategories:
                      - snacks
                    includedProductIds: []
                    excludedCategories: []
                    excludedProductIds: []
                includeAllProductsByDefault: false
                skipNonAssorted: false
              childRuleSet:
                rules:
                  - name: Order Channel
                    matchWith:
                      - online
                    multiplier: 2
              type: SALES_TARGET
              awardInstantly: false
              rewardDate: '2024-09-10T06:24:00.976Z'
              requiresOptIn: true
              vatIncluded: true
              startDate: '2024-09-01T06:26:00.976Z'
              endDate: '2024-09-05T06:15:00.976Z'
              targetEndDate: '2024-09-05T06:15:00.976Z'
              roundOffValue: 0.5
                  
      responses:
        "200":
          description: OK
          content:
            application/json:
              examples:
                Sales Target:
                  value:
                    data:
                      id: 18
                      name: UserGoal-1
                      ruleSet:
                        name:
                        rules:
                          - inclusionExclusion: INCLUSION
                            productCategory:
                              - snacks
                            productId:
                              - abc
                        includeAllProductsByDefault: false
                      type: SALES_TARGET
                      awardInstantly: false
                      rewardDate: "2024-09-02T06:24:00.976+00:00"
                      requiresOptIn: true
                      vatIncluded: true
                      startDate: "2024-08-03T06:26:00.976+00:00"
                      endDate: "2024-09-02T06:15:00.976+00:00"
                      targetEndDate: '2024-09-05T06:15:00.976Z'
                    success: true
                    message: Success
    get:
      tags:
        - "Goals"
      summary: "Retrive goals"
      description: ""
      parameters:
        - name: applicationId
          in: query
          required: false
          description: Application ID for which goals are getting retrieved
          schema:
            type: integer
        - name: page
          in: query
          required: true
          description: page number of the paginated pages
          schema:
            type: integer
        - name: limit
          in: query
          required: true
          description: limit size of the pages
          schema:
            type: integer
      responses:
        "200":
          description: OK
          content:
            application/json:
              examples:
                Sales Target:
                  value:
                    data:
                      data:
                        - id: 1
                          name: thegoalworks
                          ruleSet:
                            name:
                            rules:
                              - includedCategories: []
                                includedProductIds: []
                                excludedCategories: []
                                excludedProductIds: []
                            includeAllProductsByDefault: true
                            skipNonAssorted: false
                          type: NBA
                          awardInstantly: false
                          rewardDate: '2024-09-12T06:24:00.976+00:00'
                          requiresOptIn: true
                          vatIncluded: true
                          startDate: '2024-09-05T06:26:00.976+00:00'
                          endDate: '2024-09-10T06:15:00.976+00:00'
                          targetEndDate: '2024-09-10T06:15:00.976+00:00'
                      total_pages: 1
                      total_items: 1
                    success: true
                    message: Success
  
  /v1/goals/names:                  
    get:
      tags:
        - "Goals"
      summary: "Retrive goals by Name"
      description: ""
      parameters:
        - name: name
          in: query
          required: true
          description: Goal Name for which goals are getting retrieved
          schema:
            type: integer
      responses:
        "200":
          description: OK
          content:
            application/json:
              examples:
                Sales Target:
                  value:
                    data:
                      data:
                        - name: thegoalworks
                          ruleSet:
                            name:
                            rules:
                              - includedCategories: []
                                includedProductIds: []
                                excludedCategories: []
                                excludedProductIds: []
                            includeAllProductsByDefault: true
                            skipNonAssorted: false
                          type: NBA
                          awardInstantly: false
                          rewardDate: '2024-09-12T06:24:00.976+00:00'
                          requiresOptIn: true
                          vatIncluded: true
                          startDate: '2024-09-05T06:26:00.976+00:00'
                          endDate: '2024-09-10T06:15:00.976+00:00'
                          targetEndDate: '2024-09-10T06:15:00.976+00:00'
                      total_pages: 1
                      total_items: 1
                    success: true
                    message: Success


  /v1/goals/{id}:
    put:
      tags:
        - "Goals"
      summary: "Update Goal"
      description: ""
      parameters:
        - name: id
          in: path
          required: true
          description: Goal Id to be udpated
          schema:
            type: integer
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              applicationId: 1
              userGoalsFileUrl: http://localhost/nba.csv
              name: thegoalworks
              ruleSet:
                name:
                rules:
                  - includedCategories: []
                    includedProductIds: []
                    excludedCategories: []
                    excludedProductIds: []
                includeAllProductsByDefault: true
                skipNonAssorted: false
              type: NBA
              awardInstantly: false
              rewardDate: '2024-09-12T06:24:00.976Z'
              requiresOptIn: true
              vatIncluded: true
              startDate: '2024-09-05T06:26:00.976Z'
              endDate: '2024-09-10T06:15:00.976Z'
              targetEndDate: '2024-09-10T06:15:00.976Z'
              roundOffValue: 100
              
      responses:
        "200":
          description: OK
          content:
            application/json:
              examples:
                Sales Target:
                  value:
                    data:
                      id: 1
                      name: thegoalworks
                      ruleSet:
                        name:
                        rules:
                          - includedCategories: []
                            includedProductIds: []
                            excludedCategories: []
                            excludedProductIds: []
                        includeAllProductsByDefault: true
                        skipNonAssorted: false
                      type: NBA
                      awardInstantly: false
                      rewardDate: '2024-09-12T06:24:00.976+00:00'
                      requiresOptIn: true
                      vatIncluded: true
                      startDate: '2024-09-05T06:26:00.976+00:00'
                      endDate: '2024-09-10T06:15:00.976+00:00'
                      targetEndDate: '2024-09-10T06:15:00.976+00:00'
                    success: true
                    message: Success

  /v1/users/{storeId}/goals:
    get:
      tags:
        - "Reward Engine User"
      summary: "Retrieve Goal By User"
      description: ""
      parameters:
        - name: storeId
          in: path
          required: true
          description: storeId for which activities are getting fetched
          schema:
            type: integer
        - name: page
          in: query
          required: true
          description: page number of the paginated pages
          schema:
            type: integer
        - name: limit
          in: query
          required: true
          description: limit size of the pages
          schema:
            type: integer
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  id: 1
                  rewardEngineUserId: 1
                  pointsToAward: 1
                  targetSpendAmount: 100
                  currentSpendAmount: 0
                  progress: 0
                  taskCompletionTarget:
                success: true
                message: '200'
  
    put:
      tags:
        - "Reward Engine User"
      summary: "Update Goal Progress Of User"
      description: ""
      parameters:
        - name: storeId
          in: path
          required: true
          description: userid for which activities are getting fetched
          schema:
            type: integer
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              applicationId: 100005
              invoice: 
                totalAmount: 100
                items:
                  - name: "sku001"
                    category: "snacks"
                    price: 110
                    assorted: false
                  - name: "sku002"
                    category: "snacks"
                    price: 120
                    assorted: false
              invoiceNumber: sdfdsf123214
              channelName: online
              dateTime: '2024-10-04T06:15:00.976Z'
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  id: 5
                  rewardEngineUserId: 100005
                  pointsToAward: 150
                  targetSpendAmount: 1000
                  currentSpendAmount: 113
                  progress: 11.3
                success: true
                message: "success"
                
  /v1/users/{storeId}/goals/{goal_id}:
    patch:
      tags:
        - "Reward Engine User"
      summary: "Update opt in status of a user"
      description: ""
      parameters:
        - name: storeId
          in: path
          required: true
          description: userid for which activities are getting fetched
          schema:
            type: integer
        - name: goal_id
          in: path
          required: true
          description: id of the user-goal you ae fetching
          schema:
            type: integer
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              optInStatus: true
              applicationId: 1
                  
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  goalId: 10
                  userId: 100006
                  optInStatus: true
                success: true
                message: "success"

  /v1/points-automation:
    post:
      tags:
        - "Points Automation"
      summary: "Create config"
      requestBody:
        content:
          application/json:
            schema:
              type: object
            example:
              importFile: null
              applicationId: 1
              type: ALL
              scheduledAt: '2024-09-12T12:41:07.474Z'
              state: "pending/imported/completed"
                  
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  id: 1
                  applicationId: 1
                  scheduledAt: '2024-09-12T12:41:07.474Z'
                  importFile:
                  type: ALL
                  state: PENDING
                  createdAt: '2024-09-04T07:57:49.218555794Z'
                  updatedAt: '2024-09-04T07:57:49.218571238Z'
                success: true
                message: Success
    get:
      tags:
        - "Points Automation"
      summary: "List config"
      parameters:
        - name: sort
          in: query
          required: false
          description: sorting order
          schema:
            type: string
        - name: page
          in: query
          required: true
          description: page number of the paginated pages
          schema:
            type: integer
        - name: limit
          in: query
          required: true
          description: limit size of the pages
          schema:
            type: integer
        - name: id
          in: query
          required: false
          description: id to fetch single result
          schema:
            type: integer
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  data:
                    - id: 1
                      applicationId: 1
                      scheduledAt: '2024-09-16T12:41:07.474Z'
                      importFile: http://localhost/points-automation.csv
                      type: PARTIAL
                      state: PENDING
                      createdAt: '2024-09-04T07:57:49.218556Z'
                      updatedAt: '2024-09-04T07:58:10.445934Z'
                  total_pages: 1
                  total_items: 1
                success: true
                message: Success

  /v1/points-automation/{id}:
    put:
      tags:
        - "Points Automation"
      summary: "Update config"
      parameters:
        - name: id
          in: path
          required: true
          description: id for goal_config that will be updated
          schema:
            type: integer
      requestBody:
        content:
          application/json:
            examples:
              partial:
                summary: Partial
                value:
                  importFile: http://localhost/points-automation.csv
                  applicationId: 1
                  type: PARTIAL
                  scheduledAt: '2024-09-16T12:41:07.474Z'
                  setZero: true
                  
      responses:
        "200":
          description: OK
          content:
            application/json:
              example:
                data:
                  id: 1
                  applicationId: 1
                  scheduledAt: '2024-09-16T12:41:07.474Z'
                  importFile: http://localhost/points-automation.csv
                  type: PARTIAL
                  state: PENDING
                  createdAt: '2024-09-04T07:57:49.218556Z'
                  updatedAt: '2024-09-04T07:58:10.445933756Z'
                success: true
                message: Success

components:
  schemas:
    Problem:
      type: object
      properties:
        logRef:
          type: string
        message:
          type: string
    ErrorMessage:
      type: object
      properties:
        errors:
          type: array
          items:
            type: string
  responses:
    RewardEngineUserResponse:
      description: Successful response
      content:
        application/json:
          examples:
            success:
              value:
                data:
                  id: 124017
                  storeId: "922"
                  email: Antonina.Boehm70@hotmail.com
                  phone: "82788288"
                  totalPoints: 0.0
                success: true
                message: Success
    GETRewardEngineUserResponse:
      description: Successful response
      content:
        application/json:
          examples:
            success:
              value:
                data:
                  data:
                    - id: 1
                      storeId: "557"
                      email: Amari_Stoltenberg@hotmail.com
                      phone:
                      totalPoints: 19
                  total_pages: 1
                  total_items: 1
                success: true
                message: Success

    ActivityMasterResponse:
      description: Successful response
      content:
        application/json:
          schema:
            type: object
            properties:
              id:
                type: integer
              name:
                type: string
    ActivityHistoryResponse:
      description: Successful response
      content:
        application/json:
          schema:
            type: object
            properties:
              data:
                type: array
                items:
                  type: object
                  properties:
                    name:
                      type: string
                    store_id:
                      type: string
                    event:
                      type: string
                    points_awarded:
                      type: integer
                    reward_engine_user_id:
                      type: integer
                    points_balance_before:
                      type: integer
                    points_balance_after:
                      type: integer
                    created_at:
                      type: string
    UserActivityListingResponse:
      description: "success response"
      content:
        application/json:
          example:
            data:
              - name: Product Purchase
                storeId: '5404938'
                pointsAwarded: 400
                transferStoreId: 
                source: 'Order #sdfdsf123214'
                createdAt: '2024-09-04T07:57:52.636519Z'
            success: true
            message: Success
            

  securitySchemes:
    oktaOAuth:
      type: oauth2
      flows:
        authorizationCode:
          authorizationUrl: "https:///pepsico.oktapreview.com/oauth2/default/v1/authorize"
          tokenUrl: "https:///pepsico.oktapreview.com/oauth2/default/v1/token"
          scopes:
            openid: "OpenId connect scope"
            profile: "Access profile info"
            email: "Access email info"`;