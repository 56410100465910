// The `outputAllServers` function is used to convert the 'servers' object
// into an array of server objects, which is the format expected by the
// 'servers' key in an OpenAPI (Swagger) specification. It iterates over
// each region in the 'servers' object, and then for each region it iterates
// over each environment (dev, qa, prod), adding each server object to the
// array. It also adds a separator ('---------------') after the servers of
// each region.

export default function outputAllServers(servers) {
    let serverList = [];
    for (const region in servers) {
        for (const environment in servers[region]) {
            const server = servers[region][environment];
            serverList.push(server);
        }
        // Add a separator after the servers of each region
        serverList.push({
            "url": "---------------",
            // "description": `End of ${region} servers`
            "description": ``
        });
    }
    return serverList;
}
