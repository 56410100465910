export const SwaggerLoyaltySpec = {
"openapi": "3.0.0",
"info": {
  "title": "PepsiCo CEP Loyalty API",
  "description": "PepsiCo Consumer Engagement Platform Loyalty API",
  "version": "1.0.0"
},
"servers": [
  {
  "url": "https://cepauadmin.spo.nonprod.wspdop.pepsico.com/api/v1/"
  }
],
"paths": {
"/account": {
    "get": {
        "tags": [
            "Account"
        ],
        "summary": "Account Details",
        "description": "Account Details",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "metadata": {
                                "__comment": "Info about the JSON File.",
                                "itemKey": "customers",
                                "totalItems": 1
                            },
                            "customers": [
                                {
                                    "id": 4534,
                                    "externalId": null,
                                    "isActive": null,
                                    "creditBlock": null,
                                    "creditLimitAmount": null,
                                    "userId": "kirubaradhan@gmail.com",
                                    "role": "Customer",
                                    "memberType": "",
                                    "firstName": "testreg",
                                    "lastName": "flow",
                                    "username": null,
                                    "profileImgUrl": null,
                                    "dob": "01/01/2019",
                                    "deviceToken": "e79c2b66222a956ce04625b22e3cad3a63e91f34b1a21213a458fadb2b459385",
                                    "maid": null,
                                    "deviceType": "Android",
                                    "email": "testregflow1@fillnoo.com",
                                    "phone": null,
                                    "alternateNumber": null,
                                    "contact": {
                                        "default": true,
                                        "isStore": false,
                                        "name": null,
                                        "permissions": null,
                                        "profileStatus": null,
                                        "addresses": []
                                    },
                                    "conatact": {
                                        "points": null,
                                        "lifetimePoints": null
                                    },
                                    "preferences": {
                                        "accessType": null,
                                        "businessType": null,
                                        "executionType": null,
                                        "smsPermission": "NewOrder;OrderStatusChanged;OrderCancelled",
                                        "whatsappPermission": null,
                                        "emailPermission": "OrderShipped;OrderStatusChanged;OrderCancelled",
                                        "pushNotificationPermission": "OrderCancelled",
                                        "cookiePermission": null,
                                        "segments": []
                                    },
                                    "extra": {
                                        "totalCredit": 0,
                                        "levelId": 0,
                                        "level": null,
                                        "points": 0,
                                        "lifetimePoints": 0
                                    }

                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    },
    "put": {
        "tags": [
            "Account"
        ],
        "summary": "Account Details",
        "description": "Account Details",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "object"
                            }
                        }
                    },
                    "example": {
                        "firstName": "Test",
                        "lastName": "Tester",
                        "dob": "01/01/1991",
                        "email": "test@testaaer.com",
                        "phone": "12345678q90",
                        "maid": "3872465",
                        "notificationSettings": {
                            "SMS": [
                                "NewOrder",
                                "OrderStatusChanged",
                                "OrderCancelled"
                            ],
                            "Email": [
                                "OrderShipped",
                                "OrderStatusChanged",
                                "OrderCancelled"
                            ],
                            "PushNotification": [
                                "OrderCancelled"
                            ]
                        },
                        "termsAccepted": [
                            "Term1",
                            "Term2"
                        ],
                        "addresses": [
                            {
                                "default": true,
                                "isStore": false,
                                "name": "T033",
                                "id": "T01",
                                "companyCode": "Pepsico",
                                "type": "Sold-To / Payer",
                                "buildingNumber": 11,
                                "addressLine1": null,
                                "addressLine2": "",
                                "street": "121212",
                                "billingType": "billTo",
                                "province": "UPoosadP",
                                "city": "Noida",
                                "state": "UP",
                                "country": "IN",
                                "postalCode": 233237,
                                "taxAreaCode": null,
                                "VATNumber": 1230987,
                                "phoneNumber": 8090012316,
                                "permissions": "CEDO",
                                "profileStatus": "enrolled",
                                "points": 250,
                                "lifetimePoints": 500
                            },
                            {
                                "default": true,
                                "isStore": false,
                                "name": "T033",
                                "id": "T01",
                                "companyCode": "Pepsico",
                                "type": "Sold-To / Payer",
                                "buildingNumber": 11,
                                "addressLine1": null,
                                "addressLine2": "",
                                "street": "121212",
                                "billingType": "billTo",
                                "province": "UPP",
                                "city": "Noida",
                                "state": "UP",
                                "country": "IN",
                                "postalCode": 233237,
                                "taxAreaCode": null,
                                "VATNumber": 1230987,
                                "phoneNumber": 8090012316,
                                "permissions": "CEDO",
                                "profileStatus": "enrolled",
                                "points": 250,
                                "lifetimePoints": 500
                            }
                        ]
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                      "example" : {
                          "metadata": {
                              "__comment": "Info about the JSON File.",
                              "itemKey": "customers",
                              "totalItems": 1
                          },
                          "customers": [
                              {
                                  "id": 2694,
                                  "externalId": null,
                                  "isActive": null,
                                  "role": "Manager",
                                  "firstName": "Test",
                                  "lastName": "Tester",
                                  "username": null,
                                  "profileImgUrl": null,
                                  "dob": "01/01/1991",
                                  "deviceToken": null,
                                  "deviceType": null,
                                  "maid": "3872465",
                                  "contact": {
                                      "email": "test@testaaer.com",
                                      "phone": "12345678q90",
                                      "mobile": null,
                                      "addresses": [
                                          {
                                              "default": true,
                                              "isStore": false,
                                              "name": null,
                                              "companyCode": null,
                                              "type": "billTo",
                                              "buildingNumber": 11,
                                              "addressLine1": 121212,
                                              "addressLine2": null,
                                              "province": "UPoosadP",
                                              "city": "Noida",
                                              "state": "UP",
                                              "country": "IN",
                                              "postalCode": "IN",
                                              "taxAreaCode": "UPoosadP",
                                              "VATNumber": "",
                                              "phoneNumber": 8090012316,
                                              "permissions": null,
                                              "profileStatus": null,
                                              "points": null,
                                              "lifetimePoints": null
                                          },
                                          {
                                              "default": true,
                                              "isStore": false,
                                              "name": null,
                                              "companyCode": null,
                                              "type": "billTo",
                                              "buildingNumber": 11,
                                              "addressLine1": 121212,
                                              "addressLine2": null,
                                              "province": "UPP",
                                              "city": "Noida",
                                              "state": "UP",
                                              "country": "IN",
                                              "postalCode": "IN",
                                              "taxAreaCode": "UPP",
                                              "VATNumber": "",
                                              "phoneNumber": 8090012316,
                                              "permissions": null,
                                              "profileStatus": null,
                                              "points": null,
                                              "lifetimePoints": null
                                          }
                                      ]
                                  },
                                  "preferences": {
                                      "accessType": "DO",
                                      "businessType": "Beverage;Snack",
                                      "executionType": null,
                                      "smsPermission": "NewOrder;OrderStatusChanged;OrderCancelled",
                                      "pushNotificationPermission": "OrderCancelled",
                                      "cookiePermission": null,
                                      "language": null,
                                      "segments": []
                                  },
                                  "extra": {
                                      "totalCredit": 0,
                                      "levelId": 0,
                                      "level": null,
                                      "points": 0,
                                      "lifetimePoints": 0
                                  }
                              }
                          ]
                      }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/auth": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Registration Profile",
        "description": "Registration Profile",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "profile": {
                            "userId": "John_1@gmail.com",
                            "firstName": "John",
                            "lastName": "Patrick",
                            "role": "Customer",
                            "dob": "06/24/1997",
                            "deviceToken": "e79c2b66222a956ce04625b22e3cad3a63e91f34b1a21213a458fadb2b459385",
                            "deviceType": "Android",
                            "maid": "3872465",
                            "social": "Google",
                            "idpId": "00u1941oz5rnIBCya0h8"
                        },
                        "notificationSettings": {
                            "PushNotification": [
                                "NewOrder",
                                "OrderStatusChanged",
                                "OrderCancelled"
                            ],
                            "Email": [
                                "OrderShipped",
                                "OrderStatusChanged",
                                "OrderCancelled"
                            ]
                        },
                        "credentials": {
                            "password": {
                                "value": "JohnPatrick@123"
                            }
                        },
                        "addresses": [
                            {
                                "default": true,
                                "isStore": false,
                                "billingType": "soldTo",
                                "postalCode": 233237
                            }
                        ]
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "msg": "User registered Successfully."
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/deleteCustomer" : {
    "delete": {
        "tags": [
            "Authentication"
        ],
        "summary": "Delete Customer",
        "description": "Delete Customer",
        "parameters": [
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "Site Id",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "msg": "Profile Deleted."
                        }
                    }
                }
            },
        }
    }
},
"/auth/deactivate": {
      "post": {
        "tags": [
          "Authentication"
        ],
        "summary": "Deactivate User",
        "description": "Deactivate User",
        "parameters": [
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "msg": "User deactivated succesfully"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
    }
},
"/auth/verifyCode": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Verify Code",
        "description": "Verify Code",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "userId": "John_1@gmail.com",
                        "code": "844650"
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "msg": "Successfully verify the code."
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/auth/validateProfile": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Resend Verification Email",
        "description": "Resend Verification Email",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "userIdType": "Email || Phone",
                        "userId": "xyz@gmail.com"
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "msg": "Verification code sent on xyz@gmail.com."
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/auth/codeRedirect": {
    "get": {
        "tags": [
            "Authentication"
        ],
        "summary": "Redirect App Link & Verify Code",
        "description": "Redirect App Link & Verify Code",
        "parameters": [
            {
                "name": "SiteId",
                "in": "query",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "userId",
                "in": "query",
                "required": true,
                "description": "User Id",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "code",
                "in": "query",
                "required": true,
                "description": "Verification Code",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "text/html": {
                        "example": "<!doctype html> <html lang=\"en\"> <head> <script> window.onload = function() { const params = new URLSearchParams(window.location.search) if (params.has('userid') && params.has('code')){ let url = `peployalty://pepsicoloyaltyapp.link?code=${params.get('userid')}&userid=${params.get('code')}` window.location.replace(url) window.close() } } </script> </head> </html>"
                    }
                }
            }
        }
    }
},
"/auth/forgotpwd": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Forgot Password",
        "description": "Forgot Password",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "userIdType": "Email",
                        "username": "jeevrajshekhawat161@gmail.com"
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "expiresAt": "2022-05-30T11:30:46.000Z",
                            "status": "RECOVERY",
                            "recoveryToken": "dfpc9XkdspOcYOQDNrpo",
                            "recoveryType": "PASSWORD",
                            "_embedded": {
                                "user": {
                                    "id": "00u1737xj08GGzTDb0h8",
                                    "passwordChanged": "2022-04-19T04:55:21.000Z",
                                    "profile": {
                                        "login": "cep07-jeevrajshekhawat161@gmail.com",
                                        "firstName": "Jeevraj",
                                        "lastName": "Shekawat",
                                        "locale": "en_US",
                                        "timeZone": "America/Los_Angeles"
                                    }
                                }
                            },
                            "_links": {
                                "next": {
                                    "name": "recovery",
                                    "href": "https://pepsico.oktapreview.com/api/v1/authn/recovery/token",
                                    "hints": {
                                        "allow": [
                                            "POST"
                                        ]
                                    }
                                },
                                "cancel": {
                                    "href": "https://pepsico.oktapreview.com/api/v1/authn/cancel",
                                    "hints": {
                                        "allow": [
                                            "POST"
                                        ]
                                    }
                                }
                            },
                            "recoveryOtp": 979945
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/auth/forgotpwd/verifyRecovery": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Recovery Verify Token",
        "description": "Recovery Verify Token",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "username": "jeevrajshekhawat161@gmail.com",
                        "recoveryToken": "dfpc9XkdspOcYOQDNrpo",
                        "code": 979945
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "stateToken": "00uVbcikS-izJK3gPe0iJNxW4A25G_7BmPyxf4Kh-G",
                            "expiresAt": "2022-05-30T10:41:33.000Z",
                            "status": "PASSWORD_RESET",
                            "recoveryType": "PASSWORD",
                            "_embedded": {
                                "user": {
                                    "id": "00u1737xj08GGzTDb0h8",
                                    "passwordChanged": "2022-04-19T04:55:21.000Z",
                                    "profile": {
                                        "login": "cep07-jeevrajshekhawat161@gmail.com",
                                        "firstName": "Jeevraj",
                                        "lastName": "Shekawat",
                                        "locale": "en_US",
                                        "timeZone": "America/Los_Angeles"
                                    }
                                },
                                "policy": {
                                    "complexity": {
                                        "minLength": 8,
                                        "minLowerCase": 1,
                                        "minUpperCase": 1,
                                        "minNumber": 1,
                                        "minSymbol": 0,
                                        "excludeUsername": true
                                    },
                                    "age": {
                                        "minAgeMinutes": 0,
                                        "historyCount": 4
                                    }
                                }
                            },
                            "_links": {
                                "next": {
                                    "name": "resetPassword",
                                    "href": "https://pepsico.oktapreview.com/api/v1/authn/credentials/reset_password",
                                    "hints": {
                                        "allow": [
                                            "POST"
                                        ]
                                    }
                                },
                                "cancel": {
                                    "href": "https://pepsico.oktapreview.com/api/v1/authn/cancel",
                                    "hints": {
                                        "allow": [
                                            "POST"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/auth/credentials/reset_password": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Reset Password",
        "description": "Reset Password",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "stateToken": "00uVbcikS-izJK3gPe0iJNxW4A25G_7BmPyxf4Kh-G",
                        "newPassword": "Test@12345"
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "expiresAt": "2022-05-30T10:44:00.000Z",
                            "status": "SUCCESS",
                            "sessionToken": "20111q3qmtRBVXD28EgBEz4DQ2oHuW0CdCwjRZWGOQ7qX-NnEukdox7",
                            "_embedded": {
                                "user": {
                                    "id": "00u1737xj08GGzTDb0h8",
                                    "passwordChanged": "2022-05-30T10:38:59.000Z",
                                    "profile": {
                                        "login": "cep07-jeevrajshekhawat161@gmail.com",
                                        "firstName": "Jeevraj",
                                        "lastName": "Shekawat",
                                        "locale": "en_US",
                                        "timeZone": "America/Los_Angeles"
                                    }
                                }
                            },
                            "_links": {
                                "cancel": {
                                    "href": "https://pepsico.oktapreview.com/api/v1/authn/cancel",
                                    "hints": {
                                        "allow": [
                                            "POST"
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/auth/changepwd": {
    "post": {
        "tags": [
            "Authentication"
        ],
        "summary": "Change Password",
        "description": "Change Password",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "user_id": {
                            "value": "John@hm.com"
                        },
                        "oldPassword": {
                            "value": "JohnPatrick@123"
                        },
                        "newPassword": {
                            "value": "JohnPatrick@12345"
                        }
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "password": [],
                            "recovery_question": {
                                "question": "(1 + 2) = ? (in words)"
                            },
                            "provider": {
                                "type": "OKTA",
                                "name": "OKTA"
                            }
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/contact": {
    "post": {
        "tags": [
            "Contact"
        ],
        "summary": "Contact Submission",
        "description": "Submit contact us request",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "id": {
                                "type": "string",
                                "format": "int64"
                            }
                        }
                    },
                    "example": {
                        "inquiryId": 123,
                        "type": "Promotions",
                        "email": "john@smith.com",
                        "phone": 298765432,
                        "subject": "Reward Coupon",
                        "message": "I am having trouble redeeming my coupon for a free soda"
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": "true,",
                            "message": "Contact request submitted"
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/contact/inquiries": {
    "get": {
        "tags": [
            "Contact"
        ],
        "summary": "Contact Inquiries",
        "description": "Returns a list of contact inquiry types",
        "parameters": [
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "totalCount": 2,
                            "data": [
                                {
                                    "id": 123,
                                    "title": {
                                        "en": "General",
                                        "es": "General"
                                    }
                                },
                                {
                                    "id": 456,
                                    "title": {
                                        "en": "Rewards",
                                        "es": "Recompensas"
                                    }
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/content": {
    "get": {
        "tags": [
            "Content"
        ],
        "summary": "Content List",
        "description": "Content List",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 7
                }
            },
            {
                "name": "limit",
                "in": "query",
                "required": false,
                "description": "limit",
                "schema": {
                    "type": "number",
                    "default": 20,
                    "maximum": 100
                }
            },
            {
                "name": "offset",
                "in": "query",
                "required": false,
                "description": "offset",
                "schema": {
                    "type": "number",
                    "default": 0
                }
            },
            {
                "name": "tags",
                "in": "query",
                "required": false,
                "description": "tags",
                "schema": {
                    "type": "object",
                    "default": [
                        "TAG1",
                        "TAG2"
                    ]
                }
            },
            {
                "name": "id",
                "in": "query",
                "required": false,
                "description": "ID of Content",
                "schema": {
                    "type": "string",
                    "default": null
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "totalCount": 1,
                            "data": [
                                {
                                    "id": 61,
                                    "images": [
                                        {
                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/image1.png",
                                            "imageText": {
                                                "en": "",
                                                "es": ""
                                            },
                                            "id": "image-id-1"
                                        },
                                        {
                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/image2.png",
                                            "imageText": {
                                                "en": "",
                                                "es": ""
                                            },
                                            "id": "image-id-2"
                                        }
                                    ],
                                    "videos": [
                                        {
                                            "video": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/video1.png",
                                            "videoText": {
                                                "en": "",
                                                "es": ""
                                            },
                                            "id": "video-id-1"
                                        }
                                    ],
                                    "title": {
                                        "en": "Banner One",
                                        "es": "Banner One"
                                    },
                                    "excerpt": {
                                        "en": "Banner One",
                                        "es": null
                                    },
                                    "content": {
                                        "en": "<p>test content</p>\n",
                                        "es": "<p>test</p>\n"
                                    },
                                    "parameters": [
                                        {
                                            "key": "background-color",
                                            "value": "#ff0000"
                                        },
                                        {
                                            "key": "button-background-color",
                                            "value": "#000000"
                                        }
                                    ],
                                    "StartDate": "2022-04-29 18:30:00",
                                    "EndDate": "2022-05-12 18:30:00",
                                    "created": "2022-05-26 12:56:02",
                                    "modified": "2022-07-06 06:40:57",
                                    "displayOrder": 2,
                                    "url": "",
                                    "siteId": 8,
                                    "is_public": false,
                                    "activity": {
                                        "activityId": 217,
                                        "name": "Consume Content",
                                        "points": 0,
                                        "allowPointOverride": true,
                                        "pointsMax": 50,
                                        "dailyLimit": 0,
                                        "requireUniqueIdentifier": true
                                    },
                                    "reward": {
                                        "id": 427,
                                        "name": "Free Soda",
                                        "rewardSlug": "free-soda",
                                        "rewardActive": true
                                    },
                                    "points": 0,
                                    "promotionID": 0,
                                    "tags": [
                                        "Reward",
                                        "Deal"
                                    ]
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/content/faqs": {
    "get": {
        "tags": [
            "Content"
        ],
        "summary": "Content List",
        "description": "FAQ List",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "limit",
                "in": "query",
                "required": false,
                "description": "limit",
                "schema": {
                    "type": "number",
                    "default": 20,
                    "maximum": 100
                }
            },
            {
                "name": "offset",
                "in": "query",
                "required": false,
                "description": "offset",
                "schema": {
                    "type": "number",
                    "default": 0
                }
            },
            {
                "name": "tags",
                "in": "query",
                "required": false,
                "description": "tags",
                "schema": {
                    "type": "object",
                    "default": [
                        "TAG1",
                        "TAG2"
                    ]
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "totalCount": 1,
                            "data": [
                                {
                                    "id": 12,
                                    "title": {
                                        "en": "Frequently Asked Questions"
                                    },
                                    "description": {
                                        "en": "This is our frequently asked questions"
                                    },
                                    "image": "https://domain.com/image.png",
                                    "tags": [
                                        "General"
                                    ],
                                    "siteId": 8,
                                    "is_public": true,
                                    "created": "2022-06-06 02:25:57",
                                    "modified": "2022-07-28 05:49:35",
                                    "section": [
                                        {
                                            "title": {
                                                "en": "General Questions"
                                            },
                                            "faqs": [
                                                {
                                                    "question": {
                                                        "en": "How much does it cost?"
                                                    },
                                                    "answer": {
                                                        "en": "It costs $20"
                                                    },
                                                    "image": "https://domain.com/image2.png"
                                                },
                                                {
                                                    "question": {
                                                        "en": "How good is the quality?"
                                                    },
                                                    "answer": {
                                                        "en": "The quality is very good"
                                                    },
                                                    "image": "https://domain.com/image3.png"
                                                }
                                            ]
                                        },
                                        {
                                            "title": {
                                                "en": "Legal Questions"
                                            },
                                            "faqs": [
                                                {
                                                    "question": {
                                                        "en": "Is this legal?"
                                                    },
                                                    "answer": {
                                                        "en": "Yes, it's totally legal"
                                                    },
                                                    "image": "https://domain.com/image4.png"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/getConfiguration": {
    "get": {
        "tags": [
            "Content"
        ],
        "summary": "Configuration List",
        "description": "Configuration List",
        "parameters": [
           {
                "name": "x-api-key",
                "in": "header",
                "required": true,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "gol-enable": "Yes",
                            "gol-dashboard-enable": "Yes",
                            "app-version": "1.1",
                            "app-sub-version": "6"
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/rewards": {
    "get": {
        "tags": [
            "Rewards"
        ],
        "summary": "Reward List",
        "description": "List of available, unclaimed rewards",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "type",
                "in": "query",
                "required": false,
                "description": "Reward type",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "first_time",
                "in": "query",
                "required": false,
                "description": "First time user",
                "schema": {
                    "type": "string",
                    "default": false
                }
            },
            {
                "name": "limit",
                "in": "query",
                "required": false,
                "description": "limit",
                "schema": {
                    "type": "number",
                    "default": 20,
                    "maximum": 100
                }
            },
            {
                "name": "offset",
                "in": "query",
                "required": false,
                "description": "offset",
                "schema": {
                    "type": "number",
                    "default": 0
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "totalCount": 1,
                            "data": [
                                {
                                    "id": 999,
                                    "type": "Exclusive || Reward || WhatsNew",
                                    "title": {
                                        "en": "Reward Title"
                                    },
                                    "content": {
                                        "en": "Reward Content"
                                    },
                                    "terms": {
                                        "en": "Reward Terms & Conditions"
                                    },
                                    "buttonText": {
                                        "en": "CTA Button Text"
                                    },
                                    "disclaimer": {
                                        "en": "Disclaimer"
                                    },
                                    "buttonUrl": "https://domain.com",
                                    "value": "$5.00",
                                    "redemptionType": "wallet || show || external",
                                    "displayStartDate": "2022-06-01 00:00:00",
                                    "displayEndDate": "2022-06-31 23:59:59",
                                    "expirationDate": "2022-12-31 23:59:59",
                                    "firstTimeUser": true,
                                    "categoryPrimaryText": {
                                        "en": "Save"
                                    },
                                    "categoryPrimaryTextColor": "#ff0000",
                                    "categoryPrimaryBackgroundColor": "#000000",
                                    "categorySecondaryText": {
                                        "en": "50%"
                                    },
                                    "categorySecondaryTextColor": "#ffffff",
                                    "categorySecondaryBackgroundColor": "#00ff00",
                                    "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/image.png",
                                    "backgroundImage": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/background.png",
                                    "backgroundColor": "#ff0000",
                                    "rewardId": 427
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/rewards/claimed": {
    "get": {
        "tags": [
            "Rewards"
        ],
        "summary": "Claimed reward List",
        "description": "List of claimed rewards",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "type",
                "in": "query",
                "required": false,
                "description": "Reward type",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "burned",
                "in": "query",
                "required": false,
                "description": "Burned (true) or unburned rewards (false)",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "limit",
                "in": "query",
                "required": false,
                "description": "limit",
                "schema": {
                    "type": "number",
                    "default": 20,
                    "maximum": 100
                }
            },
            {
                "name": "offset",
                "in": "query",
                "required": false,
                "description": "offset",
                "schema": {
                    "type": "number",
                    "default": 0
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "totalCount": 1,
                            "data": [
                                {
                                    "id": 999,
                                    "type": "Exclusive || Reward || WhatsNew",
                                    "title": {
                                        "en": "Reward Title"
                                    },
                                    "content": {
                                        "en": "Reward Content"
                                    },
                                    "terms": {
                                        "en": "Reward Terms & Conditions"
                                    },
                                    "buttonText": {
                                        "en": "CTA Button Text"
                                    },
                                    "disclaimer": {
                                        "en": "Disclaimer"
                                    },
                                    "buttonUrl": "https://domain.com",
                                    "value": "$5.00",
                                    "redemptionType": "wallet || show || external",
                                    "displayStartDate": "2022-06-01 00:00:00",
                                    "displayEndDate": "2022-06-31 23:59:59",
                                    "expirationDate": "2022-12-31 23:59:59",
                                    "categoryPrimaryText": {
                                        "en": "Save"
                                    },
                                    "categoryPrimaryTextColor": "#ff0000",
                                    "categoryPrimaryBackgroundColor": "#000000",
                                    "categorySecondaryText": {
                                        "en": "50%"
                                    },
                                    "categorySecondaryTextColor": "#ffffff",
                                    "categorySecondaryBackgroundColor": "#00ff00",
                                    "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/image.png",
                                    "backgroundImage": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/background.png",
                                    "backgroundColor": "#ff0000",
                                    "rewardId": 427,
                                    "coupon": [
                                        {
                                            "couponCode": "1DFRH3",
                                            "url": "https://some.optional.url.com",
                                            "burned": true
                                        },
                                        {
                                            "couponCode": "423Z5S",
                                            "url": "https://some.optional.url.com",
                                            "burned": true
                                        }
                                    ]
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/rewards/claim/{rewardId}": {
    "post": {
        "tags": [
            "Rewards"
        ],
        "summary": "Claim reward",
        "description": "Claim reward",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "iOs",
                "in": "header",
                "required": false,
                "description": "Requesting device is iOs",
                "schema": {
                    "type": "boolean",
                    "default": false
                }
            },
            {
                "name": "rewardId",
                "in": "path",
                "required": true,
                "description": "Reward Id",
                "schema": {
                    "type": "number"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "message": "Reward claimed || Reward already claimed",
                            "redemptionType": "wallet || show || external",
                            "coupons": [
                                {
                                    "couponCode": "1DFRH3",
                                    "url": "https://some.optional.url.com",
                                    "claimed": "2022-07-12 06:53:41",
                                    "burned": true
                                },
                                {
                                    "couponCode": "423Z5S",
                                    "url": "https://some.optional.url.com",
                                    "claimed": "2022-07-12 07:30:48",
                                    "burned": true
                                }
                            ],
                            "walletBundle": "https://domain.com/path/to/bundle.zip",
                            "icon": "https://domain.com/path/to/icon.png",
                            "icon2x": "https://domain.com/path/to/icon2x.png",
                            "thumbnail": "https://domain.com/path/to/thumbnail.png",
                            "thumbnail2x": "https://domain.com/path/to/thumbnail2x.png"
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/rewards/burn/{rewardId}": {
    "post": {
        "tags": [
            "Rewards"
        ],
        "summary": "Burn reward",
        "description": "Burn reward",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "rewardId",
                "in": "path",
                "required": true,
                "description": "Reward Id",
                "schema": {
                    "type": "number"
                }
            }
        ],
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "couponCode": {
                                "type": "string"
                            }
                        }
                    },
                    "example": {
                        "couponCode": "WIN001ZX"
                    }
                }
            }
        },
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": "true,",
                            "message": "Reward burned",
                            "codes": [
                                {
                                    "couponCode": "423Z5S",
                                    "burned": "2022-07-12 07:30:48"
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/reward/activity": {
    "get": {
        "tags": [
            "Rewards"
        ],
        "summary": "Reward activity List",
        "description": "List of user reward activity",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "monthWise",
                "in": "query",
                "required": false,
                "description": "Filter data by current or last month",
                "schema": {
                    "type": "string",
                    "enum": [
                        "All",
                        "Current",
                        "Last"
                    ]
                }
            },
            {
                "name": "limit",
                "in": "query",
                "required": false,
                "description": "limit",
                "schema": {
                    "type": "number",
                    "default": 20,
                    "maximum": 100
                }
            },
            {
                "name": "offset",
                "in": "query",
                "required": false,
                "description": "offset",
                "schema": {
                    "type": "number",
                    "default": 0
                }
            },
            {
                "name": "order",
                "in": "query",
                "required": false,
                "description": "Order desc | asc",
                "schema": {
                    "type": "string",
                    "enum": [
                        "asc",
                        "desc"
                    ]
                }
            },
            {
                "name": "filter",
                "in": "query",
                "required": false,
                "description": "Filter for burn, claimed and expired",
                "schema": {
                    "type": "string",
                    "enum": [
                        "all",
                        "burn",
                        "expired",
                        "claimed"
                    ]
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "totalCount": 5,
                            "CurrentMonth": [
                                {
                                    "title": {
                                        "en": "13 June Rew 001"
                                    },
                                    "id": 2888,
                                    "type": "Reward",
                                    "redemptionType": "External",
                                    "rewardId": 2889,
                                    "expirationDate": "2022-06-30 04:00:00",
                                    "value": "$5.00",
                                    "coupon": "423Z5S",
                                    "burned": true,
                                    "expired": false,
                                    "activityDate": "4d ago"
                                }
                            ],
                            "LastMonth": [
                                {
                                    "title": {
                                        "en": "10 May Rew 001"
                                    },
                                    "id": 2888,
                                    "type": "Reward",
                                    "redemptionType": "External",
                                    "rewardId": 2889,
                                    "expirationDate": "2022-06-30 04:00:00",
                                    "value": "$5.00",
                                    "coupon": "423Z5S",
                                    "burned": true,
                                    "expired": false,
                                    "activityDate": "1m ago"
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/reward/detail/{rewardId}": {
    "get": {
        "tags": [
            "Rewards"
        ],
        "summary": "Reward Detail",
        "description": "Reward detail",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "rewardId",
                "in": "path",
                "required": true,
                "description": "Reward Id",
                "schema": {
                    "type": "number"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "data": {
                                 "title": {
                                  "en": "GOL-1"
                                 },
                                 "content": {
                                  "en": null
                                 },
                                 "terms": {
                                  "en": null
                                 },
                                 "prizeApprovedMsg": {
                                  "en": "<p>fdgfg</p>\n"
                                 },
                                 "prizePendingMsg": {
                                  "en": "<p>fdgfg</p>\n"
                                 },
                                 "prizeDeclinedMsg": {
                                  "en": "<p>fdgfg</p>\n"
                                 },
                                 "buttonText": {
                                  "en": "Redeem"
                                 },
                                 "categoryPrimaryText": {
                                  "en": null
                                 },
                                 "categorySecondaryText": {
                                  "en": null
                                 },
                                 "disclaimerTitle": {
                                  "en": null
                                 },
                                 "rewardId": "7384",
                                 "value": null,
                                 "expirationDate": "2023-01-25 04:00:00",
                                 "categoryPrimaryTextColor": "",
                                 "categoryPrimaryBackgroundColor": "",
                                 "categorySecondaryTextColor": "",
                                 "categorySecondaryBackgroundColor": "",
                                 "backgroundColor": "",
                                 "image": "http://localhost:90/Snackback%20AUS/Rewards/RewardImages/Screenshot%20from%202022-10-20%2019-55-22.png",
                                 "backgroundImage": ""
                              }
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/vendorProduct": {
    "get": {
        "tags": [
            "Campaign"
        ],
        "summary": "Loyalty Vendor & Products List",
        "description": "List of loyalty vendors & products",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "limit",
                "in": "query",
                "required": false,
                "description": "limit",
                "schema": {
                    "type": "number",
                    "default": 20,
                    "maximum": 100
                }
            },
            {
                "name": "offset",
                "in": "query",
                "required": false,
                "description": "offset",
                "schema": {
                    "type": "number",
                    "default": 0
                }
            },
            {
                "name": "order",
                "in": "query",
                "required": false,
                "description": "Order desc | asc",
                "schema": {
                    "type": "string",
                    "enum": [
                        "asc",
                        "desc"
                    ]
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "vendors": [
                                {
                                  "id": 12516,
                                    "title": {
                                        "en": "Coles"
                                    },
                                    "description": {
                                        "en": "<p>About us. Coles is&nbsp;<strong>a leading Australian retailer, with over 2,500 retail outlets nationally</strong>. Coles makes life easier for Australians by delivering quality, value and service. We process more than 20 million customer transactions each week, providing our customers with products from thousands of farmers and suppliers.</p>\n"
                                    },
                                    "termsCondition": {
                                        "en": "<p>Coles Terms &amp; Conditions</p>\n"
                                    },
                                    "media" : [
                                      {
                                      "image": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/Smiths-Crinkle.png",
                                        "type": "Logo"
                                    }
                                    ]
                                }
                            ],
                            "products": [
                                {
                                  "id": 56,
                                    "title": {
                                        "en": "Smith's Original"
                                    },
                                    "description": {
                                        "en": "<p>Our iconic Smith’s Original Crinkle Cut Chips are the ultimate, great-tasting Australian snack – an essential part of any enjoyable&nbsp;get-together.</p>\n"
                                    },
                                    "media" : [
                                      {
                                      "image": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/Smiths-Crinkle.png",
                                        "type": "Image"
                                    }
                                    ]
                                }
                            ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/campaignClaim": {
    "post": {
        "tags": [
            "Campaign"
        ],
        "summary": "Campaign Claim",
        "description": "Campaign Claim",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            }
        ],
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object",
                        "properties": {
                            "vendorId": {
                                "type": "string"
                            },
                            "productId": {
                                "type": "string"
                            },
                            "seqNo": {
                                "type": "string"
                            }
                        },
                    },
                    "example": {
                        "vendorId": "234",
                        "productId": "632",
                        "seqNo": "4",
                        "receipt": "upload file"
                    }
                }
            }
        },
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": "true,",
                            "message": "Receipt uploaded successfully",
                            "submissionId": 7540
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/revealCampaignClaim/{submissionId}": {
    "post": {
        "tags": [
            "Campaign"
        ],
        "summary": "Campaign Reveal Card",
        "description": "Campaign Reveal Card",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": false,
                "description": "okta-accesstoken(Not Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "submissionId",
                "in": "path",
                "required": true,
                "description": "Submission Id",
                "schema": {
                    "type": "number"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": "true,",
                            "message": "Success",
                             "code": 200,
                              "reward": {
                                  "rewardId": 7036,
                                  "rewardTitle": {
                                      "en": "2NovMultiUse"
                                  },
                                  "backgroundColor": "",
                                  "image": "http://localhost:90/Snackback%20AUS/Rewards/RewardImages/hoyts-card-min_2.png",
                                  "backgroundImage": "http://localhost:90/Snackback%20AUS/Rewards/RewardImages/hoyts-card-min_2.png",
                                  "callBkSuccess": {
                                      "en": null
                                  }
                              },
                              "submissionGuid": "d5127aad-6913-54eb-bc61-b602d2f69eaf",
                              "submissionStatus": "Pending",
                              "isWinner": false
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/campaignWinnerList": {
    "get": {
        "tags": [
            "Campaign"
        ],
        "summary": "Campaign Winner List",
        "description": "Campaign Winner List",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "vendor_id",
                "in": "query",
                "required": false,
                "description": "Vendor Id",
                "schema": {
                    "type": "string"
                }
            },
            {
                "name": "type",
                "in": "query",
                "required": false,
                "description": "Type",
                "schema": {
                    "type": "string",
                     "enum": [
                        "Daily",
                        "Instant"
                    ]
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "totalCount" : 1,
                            "data": [
                                 {
                                    "id": 7468,
                                    "date": "2023-01-27 09:44:10",
                                    "type": "Instant",
                                    "submissionStatus": "Pending",
                                    "rewardid": 6733,
                                    "rewardTitle": "Multi16 Code",
                                    "image": "http://localhost:90/_default_upload_bucket/hoyts-card-min_4.png",
                                    "backgroundColor": "",
                                    "backgroundImage": null,
                                    "userName": "R Reward - 2000"
                                }
                                  ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/campaign-user-tracking": {
    "get": {
        "tags": [
            "Campaign"
        ],
        "summary": "Campaign User Tracking",
        "description": "Campaign User Tracking",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                            "success": true,
                            "lastSeqNumber": "4",
                            "totUploadRecptPerDay": "3"
                            
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/loyalty/campaign-dashboard/{drawtype}": {
    "get": {
        "tags": [
            "Campaign"
        ],
        "summary": "Campaign User Dashboard",
        "description": "Campaign User Dashboard",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "drawtype",
                "in": "path",
                "required": true,
                "description": "Draw Type",
                "schema": {
                    "type": "string",
                    "enum": [
                        "Both",
                        "Instant"
                    ]
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                          "success": true,
                          "totalCount": 1,
                          "data": [
                            {
                              "id": 7468,
                              "date": "2023-01-27 09:44:10",
                              "type": "Instant",
                              "submissionStatus": "Pending",
                              "rewardid": 6733,
                              "rewardTitle": "Multi16 Code",
                              "image": "http://localhost:90/_default_upload_bucket/hoyts-card-min_4.png",
                              "backgroundColor": "",
                              "backgroundImage": null,
                              "userName": "R Reward - 2000"
                          }
                          ]
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
},
"/spin/view": {
    "get": {
        "tags": [
            "Campaign"
        ],
        "summary": "Spin & Wheel Configuration",
        "description": "Spin & Wheel Configuration",
        "parameters": [
            {
                "name": "okta-accesstoken",
                "in": "header",
                "required": true,
                "description": "okta-accesstoken(Required)",
                "schema": {
                    "type": "string",
                    "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                }
            },
            {
                "name": "SiteId",
                "in": "header",
                "required": true,
                "description": "SiteId",
                "schema": {
                    "type": "number",
                    "default": 8
                }
            },
            {
                "name": "spinId",
                "in": "query",
                "required": true,
                "description": "Spin ID",
                "schema": {
                    "type": "string"
                }
            }
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "example": {
                          "totalCount": 1,
                          "success": true,
                          "data": [
                              {
                                  "title": {
                                      "en": "The Smith’s Game of Life"
                                  },
                                  "disclaimer": {
                                      "en": "<p>Buy any pack of Smith’s Chips and play The Smith’s Game of Life for your chance to WIN!</p>\n"
                                  },
                                  "succesMessage": {
                                      "en": null
                                  },
                                  "errorMessage": {
                                      "en": null
                                  },
                                  "buttonText": {
                                      "en": "Enter The Smith's Game of Life"
                                  },
                                  "bgColor": {},
                                  "id": 18702,
                                  "spinBuyPoint": 0,
                                  "receiptUploadContent": [
                                      {
                                          "tncNotice": {
                                              "en": "<p>Yes, I am aged 18 or over and I have read and accept the Terms and Conditions and the Collection Notice.</p>\n"
                                          },
                                          "tncNoticeOr": {
                                              "en": "<p>Yes, I am aged 16 or 17, and my parent/guardian has approved my entry and has read and accepts the Terms and Conditions and the Collection Notice.</p>\n"
                                          },
                                          "collectionNotice": {
                                              "en": "<p>I have read and agree to the Collection Notice including providing my address for physical prize delivery.</p>\n"
                                          },
                                          "receiptMsg": {
                                              "en": "<p>Play The Smith’s Game of Life for your chance to WIN! Simply upload your proof of purchase to spin the wheel for 1000’s of prizes that can be won instantly!</p>\n"
                                          },
                                          "receiptUpdHelpTxt": {
                                              "en": "Make sure to upload a legible photo of your receipt."
                                          }
                                      }
                                  ],
                                  "basicSetting": [
                                      {
                                          "backgroundColor": "#6d3535",
                                          "backgroundImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/background_1.png",
                                          "spinWheelLogo": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/SpinWheelLogo.png",
                                          "img": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/Img_1.png",
                                          "designCtaTxt": "",
                                          "designCtaTitleTxt": null
                                      }
                                  ],
                                  "bannerDesign": [
                                      {
                                          "bannerBackgroundColor": "",
                                          "bannerBackgroundImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/background_3.png",
                                          "bannerImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/backgroundImg_1.png",
                                          "bannerCTATxt": "SPIN THE WHEEL",
                                          "bannerTxt": "Spin the wheel to reveal your path and get the chance to win 1000's of prizes instantly!"
                                      }
                                  ],
                                  "meta": [
                                      {
                                          "tag": "meta detail",
                                          "metaData": "0: { wheel_result: \"4\", card_type: \"action\", respin_wheel_result: \"9\", respin_card_type: \"retire_early\" },\n\n1: { wheel_result: \"6\", card_type: \"get_married\", respin_wheel_result: \"3\", respin_card_type: \"invest\" },\n\n2: { wheel_result: \"7\", card_type: \"grow_your_family\", respin_wheel_result: \"2\", respin_card_type: \"college\" },\n\n3: { wheel_result: \"1\", card_type: \"career\", respin_wheel_result: \"10\", respin_card_type: \"action\" },\n\n4: { wheel_result: \"8\", card_type: \"midlife_spinout\", respin_wheel_result: \"7\", respin_card_type: \"grow_your_family\" },\n\n5: { wheel_result: \"10\", card_type: \"action\", respin_wheel_result: \"4\", respin_card_type: \"action\" },\n\n6: { wheel_result: \"2\", card_type: \"college\", respin_wheel_result: \"7\", respin_card_type: \"house\" },\n\n7: { wheel_result: \"9\", card_type: \"retire_early\", respin_wheel_result: \"3\", respin_card_type: \"invest\" },\n\n8: { wheel_result: \"5\", card_type: \"house\", respin_wheel_result: \"10\", respin_card_type: \"action\" },\n\n9: { wheel_result: \"3\", card_type: \"invest\", respin_wheel_result: \"4\", respin_card_type: \"invest\" }"
                                      }
                                  ],
                                  "lifePathCardImages": [
                                      {
                                          "CardName": {
                                              "en": "Career"
                                          },
                                          "identifier": "career",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/01-CARD1.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "COLLEGE"
                                          },
                                          "identifier": "college",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/02-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "INVEST"
                                          },
                                          "identifier": "invest",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/03-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "ACTION"
                                          },
                                          "identifier": "action",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/04-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "HOUSE"
                                          },
                                          "identifier": "house",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/05-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "GET MARRIED"
                                          },
                                          "identifier": "get_married",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/06-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "GROW YOUR FAMILY"
                                          },
                                          "identifier": "grow_your_family",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/07-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "MIDLIFE SPINOUT"
                                          },
                                          "identifier": "midlife_spinout",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/08-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "RETIRE EARLY"
                                          },
                                          "identifier": "retire_early",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/09-CARD.png"
                                      },
                                      {
                                          "CardName": {
                                              "en": "ACTION"
                                          },
                                          "identifier": "action",
                                          "lifeCardImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/10-CARD.png"
                                      }
                                  ],
                                  "lifePathCardMessage": [
                                      {
                                          "primaryTxt": {
                                              "en": "Try your luck with a respin or tap NEXT to select a card and reveal your prize!"
                                          },
                                          "secondaryTxt": {
                                              "en": "Select one of the cards to see if you've won!"
                                          },
                                          "winingMsg": {
                                              "en": "You've won a bag of Smith's Crinkle Cut Potato Chips Original 170g! You have also been entered into our daily and major prize draw's!'"
                                          },
                                          "notWiningMsg": {
                                              "en": "Sorry you did not win this time, but you have been entered into our prize draws. In the meantime, grab another pack of Smith’s Chips for another chance to WIN INSTANTLY and more chances in the prize draw/s*"
                                          }
                                      }
                                  ],
                                  "lifePathCardDesign": [
                                      {
                                          "cardBgColor": null,
                                          "cardBackgroundImg1": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/background_2.png",
                                          "cardBgImg2": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/image%20126_1.png",
                                          "cardLogo": [
                                              {
                                                  "title": {
                                                      "en": "Your"
                                                  },
                                                  "cardLogoImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/image%20136.png"
                                              },
                                              {
                                                  "title": {
                                                      "en": "Life path"
                                                  },
                                                  "cardLogoImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/image%20137.png"
                                              },
                                              {
                                                  "title": {
                                                      "en": "Choose"
                                                  },
                                                  "cardLogoImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/image%20133%20%281%29.png"
                                              },
                                              {
                                                  "title": {
                                                      "en": "Your path"
                                                  },
                                                  "cardLogoImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/image%20134.png"
                                              }
                                          ]
                                      }
                                  ],
                                  "prizeDrawCard": [
                                      {
                                          "prizeDrawVendorId": 0,
                                          "prizeDrawVendorTitle": null,
                                          "prizeDrawType": "Major",
                                          "localizedField": [
                                              {
                                                  "prizeDrawTitle": {
                                                      "en": "You have been entered into the Major Prize Draw"
                                                  },
                                                  "prizeDrawDesc": {
                                                      "en": "<p>Sed dignissim massa vel lectus dictum pellentesque. In quis condimentum magna, sed ullamcorper dui.<br />\nNunc quis neque sit amet dolor sollicitudin tempor vel sit amet justo. Integer pulvinar neque augue, quis tempus libero dictum eu.</p>\n"
                                                  }
                                              }
                                          ],
                                          "prizeDrawDt": "2023-01-26T18:30:00.000000Z",
                                          "prizeDrawImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/Card_1.png"
                                      },
                                      {
                                          "prizeDrawVendorId": 12514,
                                          "prizeDrawVendorTitle": "Woolworths",
                                          "prizeDrawType": "Daily",
                                          "localizedField": [
                                              {
                                                  "prizeDrawTitle": {
                                                      "en": "You have been entered into the Woolworths Daily Prize Draw"
                                                  },
                                                  "prizeDrawDesc": {
                                                      "en": "<p>As you purchased a Smith’s product from Woolworths you have been entered into the Woolworths daily prize draw to win $1,000 Woolworths supermarket eGift card. Good Luck!</p>\n"
                                                  }
                                              }
                                          ],
                                          "prizeDrawDt": "2023-01-27T18:30:00.000000Z",
                                          "prizeDrawImg": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/Card.png"
                                      }
                                  ],
                                  "setting": [
                                      {
                                          "campaignId": "PEP0002",
                                          "allowSpin": 3,
                                          "frequency": "1 Day"
                                      }
                                  ],
                                  "bgImage": "https://admin.snackback-fd.au.cep.qa.pepsico.com/_default_upload_bucket/background_1.png",
                                  "wedges": [],
                                  "displayStartDate": 1674797864,
                                  "displayEndDate": 1675105200,
                                  "direction": "Clockwise",
                                  "speed": 30,
                                  "opacity": 20
                              }
                          ],
                          "assignedWedgesId": null,
                          "isAllWedgeIdAssigned": true,
                          "msg": "No assigned wedges"
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
}
},
"components": {
"schemas": {
    "Problem": {
        "type": "object",
        "properties": {
            "logRef": {
                "type": "string"
            },
            "message": {
                "type": "string"
            }
        }
    },
    "ErrorMessage": {
        "type": "object",
        "properties": {
            "errors": {
                "type": "array",
                "items": {
                    "type": "string"
                }
            }
        }
    }
}
}
};