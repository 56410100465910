import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import "swagger-ui-react/swagger-ui.css"
import Footer from './Footer/Footer'
import Header from './Header/Header'

// Import B2C Swagger Spec
import { SwaggerB2CSpec } from '../spec/B2CSpec'

function SwaggerB2C() {
  const DisableTryItOutPlugin = function() {
    return {
      statePlugins: {
        spec: {
          wrapSelectors: {
            allowTryItOutFor: () => () => false
          }
        }
      }
    }
  }
    return (
        <div className="App">
        <Header />
        <SwaggerUI spec={SwaggerB2CSpec} plugins={DisableTryItOutPlugin}  />
        <footer className="FooterClass">
          <Footer />
          </footer>
      </div>
    )
}

export default SwaggerB2C
