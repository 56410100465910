import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import Footer from './Footer/Footer'
import Header from './Header/Header'

// Import KSA Swagger Spec
import { SwaggerKSASpec } from '../spec/SwaggerKSASpec';

function SwaggerKSA() {
  const DisableTryItOutPlugin = function () {
    return {
      statePlugins: {
        spec: {
          wrapSelectors: {
            allowTryItOutFor: () => () => false
          }
        }
      }
    }
  }
  return (
    <div className="App">
      <Header />
      <SwaggerUI spec={SwaggerKSASpec} plugins={DisableTryItOutPlugin} />
      <footer className="FooterClass">
        <Footer />
      </footer>
    </div>
  )
}

export default SwaggerKSA