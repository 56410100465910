import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../assets/pepsico-logo_Black.png'
import { useOktaAuth } from '@okta/okta-react'
// import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import "../CSS/header.css"
// import { IconUserCircle } from '@tabler/icons';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


function Header() {
  const { oktaAuth } = useOktaAuth()
  const [auth, setAuth] = useState(false);

  oktaAuth.isAuthenticated().then(data => {
    setAuth(data);
  })

  let name;

  if (auth) {
    const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
    const firstName = resData.idToken.claims.FirstName;
    const lastName = resData.idToken.claims.LastName;
    name = firstName.concat(' ', lastName);
  }

  const Logout = async () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    await oktaAuth.signOut();
  };

  return (

    (auth) ?
      <>
        <header className="header">
          <nav className="navbar fixed-top">

            <div className="navbar-holder d-flex align-items-center align-middle justify-content-between">
              <div className="navbar-header">
                <Link to="/" className="navbar-brand">
                  <div className="brand-image brand-big" style={{marginTop:'30px'}}>
                    <img src={Logo} alt="logo" className="logo-big" />
                  </div>
                  <div className="brand-image brand-small">
                    <img src={Logo} alt="logo" className="logo-small" />
                  </div>
                </Link>
              </div>

              <ul className="nav-menu list-unstyled d-flex flex-md-row  align-item-center align-items-md-center pull-right listItems">
                <li className="nav-item profileIcon">
                  <AccountCircleIcon  style={{fontSize:'2em', color: '#1E1E1E'}}/>
                </li>

                <li className='nav-item profileName' style={{fontSize:'100%', color:'#1E1E1E'}}>
                 {name}
                </li>

                <li className="nav-item logoutBtn">
                  <LogoutIcon onClick={Logout}  style={{fontSize:'2em', color: '#1E1E1E', cursor:'pointer'}}/>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </>

      : <></>




  )
}

export default Header
