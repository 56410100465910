import React from 'react'
import SwaggerUI from 'swagger-ui-react'
import "swagger-ui-react/swagger-ui.css"
import Footer from './Footer/Footer'
import Header from './Header/Header'

// Import the Spec from the Specs Folder
import { SwaggerB2BSpec } from '../spec/B2BSpec'


function SwaggerB2B() {
  const DisableTryItOutPlugin = function () {
    return {
      statePlugins: {
        spec: {
          wrapSelectors: {
            allowTryItOutFor: () => () => false
          }
        }
      }
    }
  }
  return (
    <div className="App">
      <Header />
      <SwaggerUI
          spec={SwaggerB2BSpec}
          // plugins={DisableTryItOutPlugin}
      />
      <footer className="FooterClass">
        <Footer />
      </footer>
    </div>
  )
}

export default SwaggerB2B
