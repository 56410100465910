import {pepscoreServers} from "./servers";

export const SwaggerHRSpec = {
  "openapi": "3.0.0",
  "info": {
    "title": "PepsiCo CEP HR Gamification API",
    "description": "PepsiCo Consumer Engagement Platform HR Gamification API",
    "version": "0.0.3"
  },
  "servers": pepscoreServers,
  "paths": {
    "/account": {
      "get": {
        "tags": [
          "Account"
        ],
        "summary": "Account Details",
        "description": "Account Details",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "data": {
                    "gpid": "87654321",
                    "firstName": "Jane",
                    "lastName": "Smith",
                    "email": "jane.smith@pepsico.com",
                    "gender": "Male",
                    "country": "US",
                    "location": "Plano",
                    "jobFunction": "Finance",
                    "jobTitle": "Worker Bee",
                    "profilePhoto": "https://cdn.domain.com/path/to/image.png",
                    "points": 125,
                    "lifetimePoints": 250,
                    "level": "noob",
                    "badges": [
                      {
                        "id": 12566,
                        "saved": false,
                        "rewardId": 12168,
                        "metadata": "{\"image_path\":\"https:\\/\\/ceplatamhradmin.spo.nonprod.wspdop.pepsico.com\\/09362540-1669694031430.png\"}"
                      }
                    ],
                    "preferences": [
                      {
                        "key": "opt-in",
                        "value": true
                      }
                    ],
                    "logins": 2,
                    "lastLogin": "2022-09-01T18:18:18.000Z",
                    "updated": "2022-09-01T12:12:12.000Z",
                    "created": "2022-09-01T00:00:00.000Z"
                  }
                },
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      },
      "put": {
        "tags": [
          "Account"
        ],
        "summary": "Account Details",
        "description": "Account Details",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              },
              "example": {
                "firstName": "Jane",
                "lastName": "Smith",
                "country": "US",
                "gender": "Male",
                "location": "Plano",
                "jobFunction": "Finance",
                "jobTitle": "Worker Bee",
                "profilePhoto": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAAA4CAYA",
                "preferences": [
                  {
                    "key": "opt-in",
                    "value": true
                  }
                ]
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "data": {
                    "gpid": "87654321",
                    "firstName": "Jane",
                    "lastName": "Smith",
                    "email": "jane.smith@pepsico.com",
                    "gender": "Male",
                    "country": "US",
                    "location": "Plano",
                    "jobFunction": "Finance",
                    "jobTitle": "Worker Bee",
                    "profilePhoto": "https://cdn.domain.com/path/to/image.png",
                    "points": 125,
                    "lifetimePoints": 250,
                    "level": "noob",
                    "badges": [
                      {
                        "id": 12566,
                        "saved": false,
                        "rewardId": 12168,
                        "metadata": "{\"image_path\":\"https:\\/\\/ceplatamhradmin.spo.nonprod.wspdop.pepsico.com\\/09362540-1669694031430.png\"}"
                      }
                    ],
                    "preferences": [
                      {
                        "key": "opt-in",
                        "value": true
                      }
                    ],
                    "logins": 2,
                    "lastLogin": "2022-09-01T18:18:18.000Z",
                    "updated": "2022-09-01T12:12:12.000Z",
                    "created": "2022-09-01T00:00:00.000Z"
                  }
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/verifyUserLogin": {
      "get": {
        "tags": [
          "Account"
        ],
        "summary": "Validate OktaToken for Account Details",
        "description": "Validate OktaToken for Account Details",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "data": {
                    "gpid": "87654321",
                    "firstName": "Jane",
                    "lastName": "Smith",
                    "email": "jane.smith@pepsico.com",
                    "gender": "Male",
                    "country": "US",
                    "location": "Plano",
                    "jobFunction": "Finance",
                    "jobTitle": "Worker Bee",
                    "profilePhoto": "https://cdn.domain.com/path/to/image.png",
                    "points": 125,
                    "lifetimePoints": 250,
                    "level": "noob",
                    "badges": [
                      {
                        "id": 12566,
                        "saved": false,
                        "rewardId": 12168,
                        "metadata": "{\"image_path\":\"https:\\/\\/ceplatamhradmin.spo.nonprod.wspdop.pepsico.com\\/09362540-1669694031430.png\"}"
                      }
                    ],
                    "preferences": [
                      {
                        "key": "opt-in",
                        "value": true
                      }
                    ],
                    "logins": 2,
                    "lastLogin": "2022-09-01T18:18:18.000Z",
                    "updated": "2022-09-01T12:12:12.000Z",
                    "created": "2022-09-01T00:00:00.000Z"
                  }
                },
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/notification": {
      "get": {
        "tags": [
          "Notification"
        ],
        "summary": "Get all unread notifications for a user",
        "description": "Gets all UNREAD notifications for a user",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "read",
            "in": "query",
            "required": false,
            "description": "read",
            "schema": {
              "type": "string",
              "default": false,
            }
          },
          {
            "name": "limit",
            "in": "query",
            "required": false,
            "description": "limit",
            "schema": {
              "type": "number",
              "default": 20,
              "maximum": 100
            },
          },
          {
            "name": "offset",
            "in": "query",
            "required": false,
            "description": "offset",
            "schema": {
              "type": "number",
              "default": 0
            }
          },
          {
            "name": "order",
            "in": "query",
            "description": "order",
            "required": false,
            "schema": {
              "type": "string",
              "default": "ASC"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 1,
                  "data": [
                    {
                      "id": 124,
                      "title": {
                        "en": "Hello",
                        "es": "new arrival",
                        "pt": "new arrival"
                      },
                      "message": {
                        "en": "new arrival",
                        "es": "new arrival",
                        "pt": "new arrival"
                      },
                      "linkUrl": "google.com",
                      "segment": [
                        {
                          "id": 125,
                          "en": {
                            "title": "segment-1",
                            "description": "segment-1"
                          },
                          "es": {
                            "title": "segment-1",
                            "description": "segment-1 s"
                          },
                          "pt": {
                            "title": "segment-1",
                            "description": "segment-1 p"
                          }
                        },
                        {
                          "id": 126,
                          "en": {
                            "title": "segment-2",
                            "description": "segment-2 "
                          },
                          "es": {
                            "title": "segment-2",
                            "description": "segment-2 s"
                          },
                          "pt": {
                            "title": "segment-2",
                            "description": "segment-2 p"
                          }
                        }
                      ],
                      "created": "2022-10-13T13:25:09.000000Z",
                      "updated": "2022-10-13T13:25:09.000000Z",
                      "activeStartDate": "2022-10-25T19:30:00.000000Z",
                      "activeEndDate": null,
                      "important": false,
                      "tags": ["Tag1", "Tag2"]
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          },
        }
      },
      "put": {
        "tags": [
          "Notification"
        ],
        "summary": "Update notification",
        "description": "Updates one or multiple notifications to be marked as read for a specific user",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              },
              "example": {
                "read": true,
                "ids": ["520", "521"]
              }
            }
          },
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "message": "Updated Successfully"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/levels": {
      "get": {
        "tags": ["Level"],
        "summary": "Get Level Info",
        "description": "Get all configured levels and information related to each level",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            },
          },
          {
            "name": "x-api-key",
            "in": "header",
            "required": true,
            "description": "x-api-key",
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 5,
                  "data": [
                    {
                      "id": 614,
                      "title": "Lifelong Learner",
                      "pointsRequired": 5000,
                      "metadata": null
                    },
                    {
                      "id": 613,
                      "title": "Platinum Learner",
                      "pointsRequired": 3000,
                      "metadata": null
                    },
                    {
                      "id": 612,
                      "title": "Golden Learner",
                      "pointsRequired": 2000,
                      "metadata": null
                    },
                    {
                      "id": 611,
                      "title": "Super Learner",
                      "pointsRequired": 1000,
                      "metadata": null
                    },
                    {
                      "id": 610,
                      "title": "First Learner",
                      "pointsRequired": 0,
                      "metadata": "{\\\"image_path\\\":\\\"https://ceplatamhradmin.spo.nonprod.wspdop.pepsico.com/1111111121-1668017105469.png\\\"}"
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/badge": {
      "put": {
        "tags": [
          "Badge"
        ],
        "summary": "Update user badge for a program",
        "description": "Update user badge for a program",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              },
              "example": {
                "rewardId": "22337",
                "saved": true
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "message": "saved badge"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/program/save": {
      "post": {
        "tags": [
          "Programs"
        ],
        "summary": "Update RE UserGoal OptOut field value of a program",
        "description": "Changes RE UserGoal OptOut field value of a program",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              },
              "example": {
                "programid": "557",
                "save": true
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "message": "Program saved successfully"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/programs": {
      "get": {
        "tags": [
          "Programs"
        ],
        "summary": "Get one or multiple programs for a user",
        "description": "Get one or multiple programs for a user",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "limit",
            "in": "query",
            "required": false,
            "description": "limit",
            "schema": {
              "type": "number",
              "default": 20,
              "maximum": 100
            },
          },
          {
            "name": "offset",
            "in": "query",
            "required": false,
            "description": "offset",
            "schema": {
              "type": "number",
              "default": 0
            }
          },
          {
            "name": "order",
            "in": "query",
            "description": "order",
            "required": false,
            "schema": {
              "type": "string",
              "default": "desc"
            }
          },
          {
            "name": "status",
            "in": "query",
            "description": "status",
            "required": false,
            "schema": {
              "type": "string",
              "default": "active"
            }
          },
          {
            "name": "programId",
            "in": "query",
            "description": "Program ID of a single program",
            "required": false,
            "schema": {
              "type": "number"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 1,
                  "data": [
                    {
                      "id": 404,
                      "title": {
                        "en": "IT Program",
                        "es": "IT Program",
                        "pt": "IT Program"
                      },
                      "description": {
                        "en": "IT Program d",
                        "es": "IT Program",
                        "pt": "IT Program"
                      },
                      "structureDescription": {
                        "en": "IT Program s",
                        "es": "IT Program",
                        "pt": "IT Program"
                      },
                      "duration": {
                        "en": "40 h",
                        "es": "40 h",
                        "pt": "40 h"
                      },
                      "category": "IT",
                      "status": "Active",
                      "points": 120,
                      "badge": null,
                      "iconImageUrl": null,
                      "bannerImageUrl": null,
                      "startDate": "2022-11-03T18:30:00.000000Z",
                      "endDate": "2022-11-29T18:30:00.000000Z",
                      "keyDates": null,
                      "segment": null,
                      "programElement": [
                        [
                          {
                            "id": 115,
                            "title": {
                              "en": "BR-CODIGODECONDUTA-ILT",
                              "es": "BR-CODIGODECONDUTA-ILT",
                              "pt": "BR-CODIGODECONDUTA-ILT"
                            },
                            "courseId": "BR-CODIGODECONDUTA-ILT",
                            "url": "test",
                            "programElementType": "Course",
                            "points": 12,
                            "programs": null
                          },
                          {
                            "id": 129,
                            "title": {
                              "en": "br-COMPORTAMENTOSEGDTS-ILT",
                              "es": "PR2",
                              "pt": "PR2"
                            },
                            "courseId": "br-COMPORTAMENTOSEGDTS-ILT",
                            "url": "test",
                            "programElementType": "Course",
                            "points": 100,
                            "programs": null
                          }
                        ]
                      ],
                      "userGoal": {
                        "id": 591,
                        "achieved": null,
                        "optOut": null,
                        "goalStatus": "not_started",
                        "pointsAwarded": null,
                        "tierAchieved": -1
                      },
                      "goal": {
                        "goalName": "program_2",
                        "pointsNeededToAchieve": 2
                      },
                      "reward": null
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/categories": {
      "get": {
        "tags": [
          "Category"
        ],
        "summary": "Get category list",
        "description": "Get category list",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "limit",
            "in": "query",
            "required": false,
            "description": "limit",
            "schema": {
              "type": "number",
              "default": 20,
              "maximum": 100
            }
          },
          {
            "name": "offset",
            "in": "query",
            "required": false,
            "description": "offset",
            "schema": {
              "type": "number",
              "default": 0
            }
          },
          {
            "name": "order",
            "in": "query",
            "description": "order",
            "required": false,
            "schema": {
              "type": "string",
              "default": "desc"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 4,
                  "data": [
                    {
                      "id": 131,
                      "title": "R&D"
                    },
                    {
                      "id": 130,
                      "title": "IT"
                    },
                    {
                      "id": 127,
                      "title": "Business"
                    },
                    {
                      "id": 126,
                      "title": "Finance"
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/contact": {
      "post": {
        "tags": [
          "Contact"
        ],
        "summary": "Contact Submission",
        "description": "Submit contact us request",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "string",
                    "format": "int64"
                  }
                }
              },
              "example": {
                "inquiryId": 123,
                "type": "Promotions",
                "email": "john@smith.com",
                "phone": 298765432,
                "subject": "Reward Coupon",
                "message": "I am having trouble redeeming my coupon for a free soda"
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": "true,",
                  "message": "Contact request submitted"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/contact/inquiries": {
      "get": {
        "tags": [
          "Contact"
        ],
        "summary": "Contact Inquiries",
        "description": "Returns a list of contact inquiry types",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "totalCount": 2,
                  "data": [
                    {
                      "id": 123,
                      "title": {
                        "en": "General",
                        "es": "General"
                      }
                    },
                    {
                      "id": 456,
                      "title": {
                        "en": "Rewards",
                        "es": "Recompensas"
                      }
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/translation": {
      "get": {
        "tags": [
          "Translation"
        ],
        "summary": "Translations",
        "description": "Translations",
        "parameters": [
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": false,
            "description": "okta-accesstoken(Not Required)",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "x-api-key",
            "in": "header",
            "required": true,
            "description": "x-api-key",
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "latam.hr.hello": {
                    "en": "Hello",
                    "es": "Hola",
                    "pt": "olá"
                  },
                  "latam.hr.test": {
                    "en": "test1",
                    "es": "test2",
                    "pt": "test3"
                  }
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/content": {
      "get": {
        "tags": [
          "Content"
        ],
        "summary": "Content List",
        "description": "Content List",
        "parameters": [
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": false,
            "description": "okta-accesstoken(Not Required)",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "x-api-key",
            "in": "header",
            "required": true,
            "description": "x-api-key",
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "limit",
            "in": "query",
            "required": false,
            "description": "limit",
            "schema": {
              "type": "number",
              "default": 20,
              "maximum": 100
            }
          },
          {
            "name": "offset",
            "in": "query",
            "required": false,
            "description": "offset",
            "schema": {
              "type": "number",
              "default": 0
            }
          },
          {
            "name": "tags",
            "in": "query",
            "required": false,
            "description": "tags",
            "schema": {
              "type": "object",
              "default": [
                "TAG1",
                "TAG2"
              ]
            }
          },
          {
            "name": "id",
            "in": "query",
            "required": false,
            "description": "ID of Content",
            "schema": {
              "type": "string",
              "default": null
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "totalCount": 1,
                  "data": [
                    {
                      "id": 61,
                      "images": [
                        {
                          "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/image1.png",
                          "imageText": {
                            "en": "",
                            "es": ""
                          },
                          "id": "image-id-1"
                        },
                        {
                          "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/image2.png",
                          "imageText": {
                            "en": "",
                            "es": ""
                          },
                          "id": "image-id-2"
                        }
                      ],
                      "videos": [
                        {
                          "video": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/video1.png",
                          "videoText": {
                            "en": "",
                            "es": ""
                          },
                          "id": "video-id-1"
                        }
                      ],
                      "title": {
                        "en": "Banner One",
                        "es": "Banner One"
                      },
                      "excerpt": {
                        "en": "Banner One",
                        "es": null
                      },
                      "content": {
                        "en": "<p>test content</p>\n",
                        "es": "<p>test</p>\n"
                      },
                      "parameters": [
                        {
                          "key": "background-color",
                          "value": "#ff0000"
                        },
                        {
                          "key": "button-background-color",
                          "value": "#000000"
                        }
                      ],
                      "StartDate": "2022-04-29 18:30:00",
                      "EndDate": "2022-05-12 18:30:00",
                      "created": "2022-05-26 12:56:02",
                      "modified": "2022-07-06 06:40:57",
                      "displayOrder": 2,
                      "url": "",
                      "siteId": 8,
                      "is_public": false,
                      "activity": {
                        "activityId": 217,
                        "name": "Consume Content",
                        "points": 0,
                        "allowPointOverride": true,
                        "pointsMax": 50,
                        "dailyLimit": 0,
                        "requireUniqueIdentifier": true
                      },
                      "reward": {
                        "id": 427,
                        "name": "Free Soda",
                        "rewardSlug": "free-soda",
                        "rewardActive": true
                      },
                      "points": 0,
                      "promotionID": 0,
                      "tags": [
                        "Reward",
                        "Deal"
                      ],
                      "navigations": [
                        {
                            "id": 385577,
                            "title": {
                                "en": "MyHome Nav"
                            },
                            "description": {
                                "en": "MyHome"
                            },
                            "tags": [
                                "384737",
                                "384736"
                            ],
                            "displayOrder": 1,
                            "isPublic": true,
                            "metadata": "aaa meta",
                            "startDate": "2022-12-31 18:30:00",
                            "endDate": "2023-01-30 18:30:00",
                            "items": [
                                {
                                    "title": {
                                        "en": "MyHome items"
                                    },
                                    "ariaLabel": {
                                        "en": "MyHome label"
                                    },
                                    "url": "http://MyHome",
                                    "linkTarget": "_self",
                                    "image": "http://localhost:99/pepsico/products/Lays%20Crispy.jpg",
                                    "textColor": "#762c2c",
                                    "textHoverColor": "#a52f2f",
                                    "backgroundColor": "#2f1d1d",
                                    "backgroundHoverColor": "#23aa6c",
                                    "borderColor": "#c23a3a",
                                    "borderHoverColor": "#ecd645",
                                    "subItems": [
                                        {
                                            "title": {
                                                "en": "subItems"
                                            },
                                            "ariaLabel": {
                                                "en": "subItems"
                                            },
                                            "url": "subItems",
                                            "linkTarget": "_self",
                                            "image": "http://localhost:99/pepsico/carousel/1476799918_banner.jpg",
                                            "textColor": "#c45b5b",
                                            "textHoverColor": "#0f0505",
                                            "backgroundColor": "#691919",
                                            "backgroundHoverColor": "#240707",
                                            "borderColor": "#330c0c",
                                            "borderHoverColor": "#1b0606"
                                        }
                                    ]
                                }
                            ]
                          }
                      ]
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/content/faqs": {
      "get": {
        "tags": [
          "Content"
        ],
        "summary": "Content List",
        "description": "FAQ List",
        "parameters": [
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": false,
            "description": "okta-accesstoken(Not Required)",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "limit",
            "in": "query",
            "required": false,
            "description": "limit",
            "schema": {
              "type": "number",
              "default": 20,
              "maximum": 100
            }
          },
          {
            "name": "offset",
            "in": "query",
            "required": false,
            "description": "offset",
            "schema": {
              "type": "number",
              "default": 0
            }
          },
          {
            "name": "tags",
            "in": "query",
            "required": false,
            "description": "tags",
            "schema": {
              "type": "object",
              "default": [
                "TAG1",
                "TAG2"
              ]
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "totalCount": 1,
                  "data": [
                    {
                      "id": 12,
                      "title": {
                        "en": "Frequently Asked Questions"
                      },
                      "description": {
                        "en": "This is our frequently asked questions"
                      },
                      "image": "https://domain.com/image.png",
                      "tags": [
                        "General"
                      ],
                      "siteId": 8,
                      "is_public": true,
                      "created": "2022-06-06 02:25:57",
                      "modified": "2022-07-28 05:49:35",
                      "section": [
                        {
                          "title": {
                            "en": "General Questions"
                          },
                          "faqs": [
                            {
                              "question": {
                                "en": "How much does it cost?"
                              },
                              "answer": {
                                "en": "It costs $20"
                              },
                              "image": "https://domain.com/image2.png"
                            },
                            {
                              "question": {
                                "en": "How good is the quality?"
                              },
                              "answer": {
                                "en": "The quality is very good"
                              },
                              "image": "https://domain.com/image3.png"
                            }
                          ]
                        },
                        {
                          "title": {
                            "en": "Legal Questions"
                          },
                          "faqs": [
                            {
                              "question": {
                                "en": "Is this legal?"
                              },
                              "answer": {
                                "en": "Yes, it's totally legal"
                              },
                              "image": "https://domain.com/image4.png"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/program/invite": {
      "post": {
        "tags": [
          "Program invite"
        ],
        "summary": "Invite user for the program",
        "description": "Invite user to the program which is paased in header",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              },
              "example": {
                "programId": "778",
                "employeeEmail": "smith@gmail.com"
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "success": true,
                  "message": "User Added to program"
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/users/scorecard": {
      "get": {
        "tags": [
          "Users Scorecard"
        ],
        "summary": "Find the ranking of user among all users",
        "description": "Find current user rank on basis of Job Funtion, Country and Segment",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 2,
                  "data": [
                    {
                      "gpid": "69792661",
                      "name": "country",
                      "rank": "1"
                    },
                    {
                      "gpid": "69792661",
                      "name": "job function",
                      "rank": "1"
                    },
                    {
                      "gpid": "69792661",
                      "name": "segment",
                      "rank": "3"
                    },
                    {
                      "gpid": "69792661",
                      "name": "allUsers",
                      "rank": "3"
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    },
    "/user/search": {
      "get": {
        "tags": [
          "Search User"
        ],
        "summary": "Searching user",
        "description": "Searching user from the search box",
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "properties": {
                  "id": {
                    "type": "object"
                  }
                }
              },
              "example": {
                "name": "john"
              }
            }
          }
        },
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          },
          {
            "name": "x-api-key",
            "in": "header",
            "required": true,
            "description": "x-api-key",
            "schema": {
              "type": "string",
              "default": "ba8cbf95ae676306b6394cfd57ff4266"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 2,
                  "data": [
                    {
                      "firstName": "Luv",
                      "lastName": "Singh",
                      "email": "luv@gmail.com",
                      "gpid": "111111111"
                    },
                    {
                      "firstName": "luvs",
                      "lastName": "singhs",
                      "email": "luvv@gmail.com",
                      "gpid": "21212"
                    }
                  ]
                }
              }
            }
          }
        },
        "400": {
          "description": "Bad Request",
          "content": {
            "*/*": {
              "schema": {
                "$ref": "#/components/schemas/ErrorMessage"
              }
            }
          }
        },
        "415": {
          "description": "Unsupported Media Type",
          "content": {
            "*/*": {
              "schema": {
                "$ref": "#/components/schemas/ErrorMessage"
              }
            }
          }
        },
        "500": {
          "description": "Internal Server Error",
          "content": {
            "*/*": {
              "schema": {
                "$ref": "#/components/schemas/Problem"
              }
            }
          }
        }
      }
    },
    "/programs/rank": {
      "get": {
        "tags": [
          "Programs Rank"
        ],
        "summary": "List programs ranking",
        "description": "Programs ranking on the basis of users in",
        "parameters": [
          {
            "name": "SiteId",
            "in": "header",
            "required": true,
            "description": "SiteId",
            "schema": {
              "type": "number",
              "default": 9
            }
          },
          {
            "name": "okta-accesstoken",
            "in": "header",
            "required": true,
            "description": "okta-accesstoken",
            "schema": {
              "type": "string",
              "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "example": {
                  "count": 4,
                  "data": [
                    {
                      "Top100Program": [
                        {
                          "programId": 313,
                          "programName": "aaaaaaaa",
                          "programStatus": ""
                        },
                        {
                          "programId": 346,
                          "programName": "aaaaaa",
                          "programStatus": ""
                        },
                        {
                          "programId": 303,
                          "programName": "TitleEnglish",
                          "programStatus": "Pending"
                        }
                      ],
                      "Top50Program": [],
                      "Top20Program": [],
                      "Top10Program": []
                    }
                  ]
                }
              }
            }
          },
          "400": {
            "description": "Bad Request",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "415": {
            "description": "Unsupported Media Type",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/ErrorMessage"
                }
              }
            }
          },
          "500": {
            "description": "Internal Server Error",
            "content": {
              "*/*": {
                "schema": {
                  "$ref": "#/components/schemas/Problem"
                }
              }
            }
          }
        }
      }
    }
  },
  "components": {
    "schemas": {
      "User": {
        "type": "object",
        "properties": {
          "gpid": {
            "type": "string"
          },
          "firstName": {
            "type": "string"
          },
          "lastName": {
            "type": "string"
          },
          "email": {
            "type": "string"
          },
          "country": {
            "type": "string"
          },
          "location": {
            "type": "string"
          },
          "jobFunction": {
            "type": "string"
          },
          "jobTitle": {
            "type": "string"
          },
          "profilePhoto": {
            "type": "string"
          },
          "points": {
            "type": "integer",
          },
          "lifetimePoints": {
            "type": "integer",
          },
          "level": {
            "type": "string",
          },
          "rewards": {
            "type": "array",
          },
          "preferences": {
            "type": "array",
            "items": {
              "properties": {
                "key": {
                  "type": "string"
                },
                "value": {
                  "type": "boolean"
                }
              }
            }
          },
          "logins": {
            "type": "integer",
          },
          "lastLogin": {
            "type": "datetime"
          },
          "updated": {
            "type": "datetime"
          },
          "created": {
            "type": "datetime"
          }
        }
      },
      "Notification": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer"
          },
          "title": {
            "type": "string"
          },
          "message": {
            "type": "string"
          },
          "segment": {
            "type": "string"
          },
          "category": {
            "type": "string"
          },
          "created": {
            "type": "datetime"
          },
          "updated": {
            "type": "datetime"
          },
          "siteId": {
            "type": "integer"
          },
          "linkUrl": {
            "type": "string"
          },
          "important": {
            "type": "boolean"
          },
          "activeStartDate": {
            "type": "datetime"
          },
          "activeEndDate": {
            "type": "datetime"
          },
          "tags": {
            "type": "array",
            "items": {
              "type": "string"
            }
          },
          "users": {
            "type": "array",
            "items": {
              "properties": {
                "gpid": {
                  "type": "string"
                },
                "read": {
                  "type": "boolean"
                },
                "readDate": {
                  "type": "datetime"
                }
              }
            }
          }
        }
      },
      "Program": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer"
          },
          "siteId": {
            "type": "integer"
          },
          "bannerImageUrl": {
            "type": "string"
          },
          "iconImageUrl": {
            "type": "string"
          },
          "title": {
            "type": "string"
          },
          "description": {
            "type": "string"
          },
          "duration": {
            "type": "string"
          },
          "startDate": {
            "type": "datetime"
          },
          "endDate": {
            "type": "datetime"
          },
          "structureDescription": {
            "type": "string"
          },
          "status": {
            "type": "string"
          },
          "points": {
            "type": "integer"
          },
          // "tags": {
          //   "type": "array",
          //   "items": {
          //     "type": "string"
          //   }
          // },
          "keyDates": {
            "type": "array",
            "items": {
              "properties": {
                "title": {
                  "type": "string"
                },
                "description": {
                  "type": "string"
                },
                "date": {
                  "type": "datetime"
                }
              }
            }
          }
        }
      },
      "Program Element": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer"
          },
          "siteId": {
            "type": "integer"
          },
          "courseId": {
            "type": "string"
          },
          "title": {
            "type": "string"
          },
          "url": {
            "type": "string"
          },
          "type": {
            "type": "string"
          },
          "provider": {
            "type": "string"
          },
          "location": {
            "type": "string"
          },
          "startDate": {
            "type": "datetime"
          },
          "duration": {
            "type": "string"
          },
          "points": {
            "type": "integer"
          }
        }
      },
      "Segment": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer"
          },
          "slug": {
            "type": "string"
          },
          "title": {
            "type": "string"
          },
          "description": {
            "type": "string"
          }
        }
      },
      "Level": {
        "type": "object",
        "properties": {
          "id": {
            "type": "integer"
          },
          "title": {
            "type": "string"
          },
          "pointsRequired": {
            "type": "integer"
          },
          "metadata": {
            "type": "object"
          }
        }
      },
      "Problem": {
        "type": "object",
        "properties": {
          "logRef": {
            "type": "string"
          },
          "message": {
            "type": "string"
          }
        }
      },
      "ErrorMessage": {
        "type": "object",
        "properties": {
          "errors": {
            "type": "array",
            "items": {
              "type": "string"
            }
          }
        }
      }
    }
  }
};
