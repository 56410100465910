import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "../CSS/sidebar.css"
import { useOktaAuth } from '@okta/okta-react'

function Sidebar() {

  const { oktaAuth } = useOktaAuth()

  const [id, setId] = useState(1)
  const [auth, setAuth] = useState(false);

  oktaAuth.isAuthenticated().then(data => {
    setAuth(data);
  })

  const onClick = (id) => {
    setId(id)
  }

  return (
    (auth) ? <>
        <div className="default-sidebar">
          <nav className="side-navbar box-scroll sidebar-scroll" >
            <ul className="list-unstyled">
              <li className='list-element dashboard-list-element'>
                <Link to="/swagger" key="1" className={id === 1 ? 'active' : ''} onClick={() => onClick(1)}>
                  <span style={{ textAlign:'left', font:"normal normal bold 16px/48px Roboto", letterSpacing:'0px', color:'#FFFFFF'}}>Swagger</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        </> : <></>
  )
}

export default Sidebar
