/**
 * This file contains the server configurations for different regions and environments.
 * Each region (e.g., Turkey, Colombia) has a corresponding object containing the URL and
 * description for the Development, QA, and Production environments.
 *
 * You can easily access the server information for a given region and environment by using the
 * following pattern: servers.<region>.<environment>. For example, servers.turkey.dev.url will
 * give you the URL for the development server in Turkey.
 *
 * This structure provides a centralized and organized way to manage and access the server URLs
 * and descriptions across different regions and environments.
 */



export const newZealandServers = {
    dev: {
        "url": "https://admin-latam.cep.dev.pepsico.com/",
        "description": 'Dev Server - LATAM - New Zealand'
    },
    qa: {
        "url": "https://admin.cep.qa.nz.pepsico.com/",
        "description": "QA Server - New Zealand"
    },
    prod: {
        "url": "https://admin.cep.nz.pepsico.com/",
        "description": "Production Server - New Zealand"
    },
};

export const mexicoServers = {
    dev: {
        "url": "https://admin-latam.cep.dev.pepsico.com/",
        "description": 'Dev Server - LATAM - Mexico'
    },
    sit: {
        "url": "https://admin-latam.cep.sit.pepsico.com/",
        "description": "SIT Server - Mexico"
    },
    qa: {
        "url": "https://admin.cep.qa.mx.pepsico.com/",
        "description": "QA Server - Mexico"
    },
    prod: {
        "url": "https://admin.cep.mx.pepsico.com/",
        "description": "Production Server - Mexico"
    },
};

export const brazilServers = {
    dev: {
        "url": "https://admin-latam.cep.dev.pepsico.com/",
        "description": 'Dev Server - LATAM - Brazil'
    },
    qa: {
        "url": "https://admin.cep.qa.br.pepsico.com/",
        "description": "QA Server - Brazil"
    },
    prod: {
        "url": "https://admin.cep.br.pepsico.com/",
        "description": "Production Server - Brazil"
    },
};

export const dominicanRepublicServers = {
    dev: {
        "url": "https://admin-latam.cep.dev.pepsico.com/",
        "description": 'Dev Server - LATAM - Dominican Republic'
    },
    qa: {
        "url": "https://admin.cep.qa.do.pepsico.com/",
        "description": "QA Server - Dominican Republic"
    },
    prod: {
        "url": "https://admin.cep.do.pepsico.com/",
        "description": "Production Server - Dominican Republic"
    },
};

export const colombiaServers = {
    dev: {
        "url": "https://admin-latam.cep.dev.pepsico.com/",
        "description": 'Dev Server - LATAM - Colombia'
    },
    qa: {
        "url": "https://admin.cep.qa.co.pepsico.com/",
        "description": "QA Server - Colombia"
    },
    prod: {
        "url": "https://admin.cep.co.pepsico.com/",
        "description": "Production Server - Colombia"
    },
};

export const turkeyServers = {
    dev: {
        "url": "https://admin-eu.cep.dev.pepsico.com/",
        "description": 'Development Server - Europe - Turkey'
    },
    qa: {
        "url": "https://admin.cep.qa.tr.pepsico.com/",
        "description": 'QA Server - Turkey'
    },
    prod: {
        "url": "https://admin.cep.tr.pepsico.com/",
        "description": 'Production Server - Turkey'
    },
};


export const spainServers = {
    dev: {
        "url": "https://admin-eu.cep.dev.pepsico.com/",
        "description": 'Dev Server - Europe - Spain'
    },
    qa: {
        "url": "https://admin.cep.qa.es.pepsico.com/",
        "description": "QA Server - Spain"
    },
    prod: {
        "url": "https://admin.cep.es.pepsico.com/",
        "description": "Production Server - Spain"
    },
};
export const polandServers = {
    qa: {
        "url": "https://admin.cep.pl.qa.pepsico.com/",
        "description": 'QA Server - Poland'
    }
};

export const pepscoreServers = {
    dev: {
        "url": "https://ceplatamhradmin.spo.nonprod.wspdop.pepsico.com/",
        "description": 'Dev Server - Pepscore'
    },
    qa: {
        "url": "https://admin.pepscore.qa.pepsico.com/",
        "description": "QA Server - Pepscore"
    },
    prod: {
        "url": "https://admin.pepscore.pepsico.com/",
        "description": "Production Server - Pepscore"
    },
}


export const servers = {
    newZealandServers: newZealandServers,
    mexico: mexicoServers,
    brazil: brazilServers,
    dominicanRepublic: dominicanRepublicServers,
    colombia: colombiaServers,
    turkey: turkeyServers,
    spain: spainServers,
    poland: polandServers,

    // ... any other regions
};

export const bdrServers = {
    brazil: brazilServers,
    colombia: colombiaServers,
    dominicanRepublic: dominicanRepublicServers,
};

