import footerRightImg from "../../assets/CEPLogoContent.png"

export default function Footer() {
  return (
    <div className="footer-container pt-3">
          <form className="form-card">
            <div className="container">
              <div className="row ">
                <div className="form-group col-sm-10 flex-column d-flex footer-content ">
                  <div className="row" ></div>
                  <div className="footer-content-1" >
                    <div className="row text-left pb-1">
                      {/* <div className="footer-circle inline"></div>
                      <div style={{ color: '#ffffff'}} className='inline'>pepsicodopopsteam@pepsico.com.</div> */}
                        <div className="footer-circle"></div>
                      <div className="form-group col-sm-11 flex-column d-flex footer-content" style={{ paddingLeft: '1%' }}>
                        <div style={{ color: '#ffffff' }}>pepsicodopopsteam@pepsico.com</div>
                      </div>
                    </div>
                    <div className="row text-left p-1" >
                      <div>
                        <p className="footer-line">We are here to help you please send us email if you have any questions.</p>
                        <p className="footer-line">Expected response Monday through Friday, from 9:00 a.m to 5:00 p.m.</p>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="form-group col-sm-2 flex-column d-flex footer-image" style={{marginTop: '11px'}}>
                  <img src={footerRightImg} style={{ height: '55px', width: '100px' }} alt="Error Img"></img>
                </div>
              </div>
              <hr style={{margin: 0}}/>
              <div className="row text-left">
                      <p className="Copyright">© 2022 PepsiCo Inc.</p>
              </div>
            </div>
          </form>

        </div>
  )
}
