import {dominicanRepublicServers, colombiaServers} from './servers/index';
import outputAllServers from "../util/outputAllServers";
import {bdrServers} from "./servers";

export const SwaggerB2RSpec = {
    "openapi": "3.0.0",
    "info": {
        "title": "PepsiCo CEP B2B API",
        "description": "PepsiCo Consumer Engagement Platform B2B API",
        "version": "1.0.0"
    },
    "servers": outputAllServers(bdrServers),
    "paths": {
        "/auth/validateTaxId": {
            "post": {
                "tags": [
                    "Authentication"
                ],
                "summary": "Auth Profile - Validate the Tax ID",
                "description": "Auth Profile - Validate the Tax ID",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "object",
                                "properties": {
                                    "id": {
                                        "type": "string",
                                        "format": "int64"
                                    }
                                }
                            },
                            "example": {
                                "erpId": "1212",
                                "taxId": "31443324"
                            }
                        }
                    }
                },
                "parameters": [
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "SiteId",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "x-api-key",
                        "in": "header",
                        "required": true,
                        "description": "x-api-key",
                        "schema": {
                            "type": "string",
                            "default": "00Ynu7Osnis3pcorDPsI2T7lK_Cs32gkP2wQU4K46X"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "success": true,
                                    "msg": "Profile Validated"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/account": {
            "get": {
                "tags": [
                    "Account"
                ],
                "summary": "Account Details",
                "description": "Get Account details for a specific store",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "metadata": {
                                        "__comment": "Info about the JSON File.",
                                        "itemKey": "customers",
                                        "totalItems": 1
                                    },
                                    "customers": {
                                        "id": 249548,
                                        "userId": "4137095123@pepsiconnect.com",
                                        "role": "Manager",
                                        "memberType": "",
                                        "firstName": "ANYI",
                                        "lastName": "PAOLA",
                                        "profileImgUrl": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/CustomerImages/7/Fireworks%20Coloring%20Page%20%20%20Disney%20LOL-1658407922004.png",
                                        "dob": null,
                                        "deviceToken": null,
                                        "maid": null,
                                        "deviceType": null,
                                        "email": "test1@gmail.com",
                                        "phone": "311876297",
                                        "alternateNumber": "321321321312",
                                        "contact": [
                                            {
                                                "isActive": true,
                                                "creditBlock": "yes",
                                                "creditLimitAmount": "0",
                                                "mobile": "4137095123",
                                                "default": true,
                                                "isStore": true,
                                                "storeId": "0100104110",
                                                "name": "OLIMPICA",
                                                "nickName": null,
                                                "minimumOrderAmount": 40,
                                                "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/StoreMapImages/7/Fireworks%20Coloring%20Page%20%20%20Disney%20LOL-0100104110-1658407851730.png",
                                                "companyCode": null,
                                                "type": "",
                                                "postalCode": "1111",
                                                "VATNumber": null,
                                                "phoneNumber": null,
                                                "permissions": null,
                                                "profileStatus": null,
                                                "points": 1000,
                                                "lifetimePoints": 902,
                                                "addresses": {
                                                    "addressLine1": "CL 138 52 03",
                                                    "addressLine2": " ",
                                                    "city": "MOSQUERA",
                                                    "state": "52",
                                                    "country": "CO"
                                                }
                                            }
                                        ],
                                        "customAddresses": [
                                            {
                                                "name": "Golu Tulshyan",
                                                "isdefault": true,
                                                "position": 0,
                                                "type": "shipTo",
                                                "buildingNumber": "2",
                                                "addressLine1": "ABC",
                                                "addressLine2": null,
                                                "province": "3234234",
                                                "city": "dfdfdf",
                                                "district": "Kolkata",
                                                "state": "ewrwer",
                                                "country": "IN",
                                                "postalCode": "534534534",
                                                "taxAreaCode": "3234234",
                                                "phoneNumber": 324324234
                                            }
                                        ],
                                        "termsAccepted": [
                                            {
                                                "contentId": "terms-and-conditions",
                                                "version_number": "1",
                                                "deviceSerial": "123456789",
                                                "ipAddress": "192.168.0.1",
                                                "date": "2022-11-22",
                                                "time": "12:15"
                                            },
                                            {
                                                "contentId": "privacy-policy",
                                                "version_number": "2",
                                                "deviceSerial": "23456789",
                                                "ipAddress": "192.168.0.1",
                                                "dateTime": "2022-11-13",
                                                "date": "2022-11-13",
                                                "time": "11:15"
                                            }
                                        ],
                                        "preferences": {
                                            "accessType": null,
                                            "businessType": null,
                                            "executionType": null,
                                            "smsPermission": "NewOrder;OrderStatusChanged;OrderCancelled",
                                            "whatsappPermission": null,
                                            "emailPermission": "OrderShipped;OrderStatusChanged;OrderCancelled",
                                            "pushNotificationPermission": null,
                                            "cookiePermission": null,
                                            "segments": []
                                        },
                                        "extra": {
                                            "totalCredit": 0,
                                            "levelId": 0,
                                            "level": null,
                                            "points": 0,
                                            "lifetimePoints": 0
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            },
        },
        "/order": {
            "get": {
                "tags": [
                    "Order"
                ],
                "summary": "Get Order History",
                "description": "Get orders history list",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "StoreId",
                        "in": "header",
                        "required": true,
                        "description": "StoreId",
                        "schema": {
                            "type": "string",
                            "default": "0100104110"
                        }
                    },
                    {
                        "name": "orderType",
                        "in": "query",
                        "required": false,
                        "description": "Order type",
                        "schema": {
                            "type": "string",
                            "default": "ZOR",
                            "enum": [
                                "ZOR",
                                "Rewards"
                            ]
                        }
                    },
                    {
                        "name": "orderNumber",
                        "in": "query",
                        "required": false,
                        "description": "Order Number",
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "from",
                        "in": "query",
                        "required": false,
                        "description": "Order Date start from",
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "till",
                        "in": "query",
                        "required": false,
                        "description": "Order Date end from",
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "limit",
                        "in": "query",
                        "required": false,
                        "description": "limit",
                        "schema": {
                            "type": "number",
                            "default": 10,
                            "maximum": 100
                        }
                    },
                    {
                        "name": "offset",
                        "in": "query",
                        "required": false,
                        "description": "offset",
                        "schema": {
                            "type": "number",
                            "default": 0
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "metadata": {
                                        "__comment": "Info about the JSON File. ",
                                        "itemKey": "orders",
                                        "totalItems": 1
                                    },
                                    "orders": [
                                        {
                                            "id": 460,
                                            "orderType": "ZOR",
                                            "orderStatus": "",
                                            "orderNumber": "ord_62a1afc8e5c35",
                                            "erpOrderNumber": null,
                                            "poNumber": null,
                                            "ediNumber": null,
                                            "invoiceNumber": null,
                                            "orderLocationId": "123",
                                            "orderDate": "2022-06-09",
                                            "poDate": "",
                                            "estimatedDeliveryDate": null,
                                            "deliveryDate": null,
                                            "erpShippingCondition": "S1",
                                            "productDiscount": 0,
                                            "promotionalDiscount": 0,
                                            "totalTax": 5.25,
                                            "subtotal": 165,
                                            "totalPrice": 170.25,
                                            "estimatedPoints": 65,
                                            "deliveredPoints": 70,
                                            "paymentTermCode": "1234",
                                            "currency": "EUR",
                                            "routeId": null,
                                            "routeRepId": null,
                                            "routeOrderTypeCode": null,
                                            "routeOrderTypeSubCode": null,
                                            "routeVisitSchedule": null,
                                            "routeMarketplaceId": null,
                                            "sourceOrderId": "Portal",
                                            "sourceSystemId": null,
                                            "voucherCode": null,
                                            "items": [
                                                {
                                                    "id": 12,
                                                    "externalId": "",
                                                    "name": {
                                                        "en": "Test Product NAme",
                                                        "es": "Test Product NAme Spanish"
                                                    },
                                                    "imageURL": "",
                                                    "quantity": 11,
                                                    "quantityFulfilled": null,
                                                    "fulfilledPrice": null,
                                                    "baseUnit": "EA",
                                                    "saleUnit": "Case",
                                                    "converstionMultiplierToBase": 12,
                                                    "basePrice": 1.5,
                                                    "finalPrice": 12.5,
                                                    "categoryId": null,
                                                    "isPromotional": false,
                                                    "isSubstituted": false,
                                                    "substitutedById": null,
                                                    "status": "committed",
                                                    "gtInValue": null,
                                                    "rejectedReason": null,
                                                    "deliveryMethod": null,
                                                    "digitalAddress": null,
                                                    "type": "Digital",
                                                    "counponInfo": [
                                                        {
                                                            "code": "010206",
                                                            "codeExpiredDate": "2023-12-30",
                                                            "expired": false
                                                        }
                                                    ]
                                                }
                                            ],
                                            "shippingAddress": {
                                                "externalId": null,
                                                "customerName": null,
                                                "email": null,
                                                "phone": null,
                                                "addressLine1": null,
                                                "addressLine2": null,
                                                "city": null,
                                                "countryCode": null
                                            },
                                            "billingAddress": {
                                                "externalId": null,
                                                "customerName": null,
                                                "email": null,
                                                "phone": null,
                                                "addressLine1": null,
                                                "addressLine2": "",
                                                "city": null,
                                                "countryCode": null
                                            },
                                            "customerAddress": {
                                                "externalId": null,
                                                "customerName": null,
                                                "email": null,
                                                "phone": null,
                                                "addressLine1": null,
                                                "addressLine2": null,
                                                "city": null,
                                                "countryCode": null
                                            },
                                            "orderNote": null,
                                            "rejectedNote": null
                                        }
                                    ],
                                    "rewardType": [
                                        "Cash",
                                        "Digital Pre-Order",
                                        "Product",
                                        "Digital",
                                        "Physical"
                                    ],
                                    "rewardTypeCount": 5
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/promotion/rule": {
            "get": {
                "tags": [
                    "Promotion"
                ],
                "summary": "Promotion Rules - List",
                "description": "Get Rules associated with promotions",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "SiteId",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "storeId",
                        "in": "query",
                        "required": true,
                        "description": "storeId",
                        "schema": {
                            "type": "string",
                            "default": "TEST STORE"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "metadata": {
                                        "totalItems": 4
                                    },
                                    "promotions": [
                                        {
                                            "id": "6",
                                            "title": {
                                                "en": "OrderOnlineGetFreeItem",
                                                "es": "OrderOnlineGetFreeItem"
                                            },
                                            "description": "",
                                            "startDate": "2021-12-31 18:30:00",
                                            "endDate": "2023-07-31 17:30:00",
                                            "isPublic": false,
                                            "isActive": "1",
                                            "behavior": "additiv",
                                            "actions": [
                                                {
                                                    "type": "OrdGyDiscount",
                                                    "message": {
                                                        "en": "",
                                                        "es": ""
                                                    },
                                                    "fields": [
                                                        {
                                                            "reward_qty": 2
                                                        },
                                                        {
                                                            "product": 2498
                                                        },
                                                        {
                                                            "maxAllowedUsage": 1
                                                        },
                                                        {
                                                            "resetFrequency": 28
                                                        }
                                                    ]
                                                }
                                            ],
                                            "conditions": [
                                                {
                                                    "operator": "and",
                                                    "type": "DateRange",
                                                    "starting": "06.04.2022",
                                                    "ending": "31.12.2022"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "5",
                                            "title": {
                                                "en": "BuyXGetY",
                                                "es": "BuyXGetY"
                                            },
                                            "description": "",
                                            "startDate": "2021-12-31 18:30:00",
                                            "endDate": "2022-09-29 18:30:00",
                                            "isPublic": false,
                                            "isActive": "1",
                                            "behavior": "additiv",
                                            "actions": [
                                                {
                                                    "type": "BxGyDiscount",
                                                    "message": {
                                                        "en": "Buy 3 Get 3 Free!",
                                                        "es": "Compre 3 y obtenga 3 gratis"
                                                    },
                                                    "fields": [
                                                        {
                                                            "buy_qty": 3
                                                        },
                                                        {
                                                            "reward_qty": 3
                                                        },
                                                        {
                                                            "product_quota": 0
                                                        },
                                                        {
                                                            "gift_product": 2495
                                                        },
                                                        {
                                                            "max_allowed_items": 60
                                                        },
                                                        {
                                                            "maxAllowedUsage": 4
                                                        },
                                                        {
                                                            "resetFrequency": 1
                                                        }
                                                    ]
                                                }
                                            ],
                                            "conditions": [
                                                {
                                                    "operator": "and",
                                                    "type": "CatalogProduct",
                                                    "products": [
                                                        2484,
                                                        2469
                                                    ]
                                                },
                                                {
                                                    "operator": "and",
                                                    "type": "DateRange",
                                                    "starting": "01.06.2022",
                                                    "ending": "31.12.2022"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "13",
                                            "title": {
                                                "en": "Fixed % Discount",
                                                "es": "Fixed % Discount"
                                            },
                                            "description": "",
                                            "startDate": "2021-12-31 18:30:00",
                                            "endDate": "2023-12-31 17:30:00",
                                            "isPublic": false,
                                            "isActive": "1",
                                            "behavior": "additiv",
                                            "actions": [
                                                {
                                                    "type": "ProductDiscount",
                                                    "message": {
                                                        "en": "",
                                                        "es": ""
                                                    },
                                                    "fields": [
                                                        {
                                                            "amount": 0
                                                        },
                                                        {
                                                            "percent": 100
                                                        },
                                                        {
                                                            "maxAllowedUsage": 8
                                                        },
                                                        {
                                                            "resetFrequency": null
                                                        }
                                                    ]
                                                }
                                            ],
                                            "conditions": [
                                                {
                                                    "operator": "and",
                                                    "type": "DateRange",
                                                    "starting": "01.03.2022",
                                                    "ending": "31.01.2023"
                                                }
                                            ]
                                        },
                                        {
                                            "id": "14",
                                            "title": {
                                                "en": "BuyXGetY2",
                                                "es": "BuyXGetY2"
                                            },
                                            "description": "",
                                            "startDate": "2021-12-31 18:30:00",
                                            "endDate": "2022-09-29 18:30:00",
                                            "isPublic": false,
                                            "isActive": "1",
                                            "behavior": "additiv",
                                            "actions": [
                                                {
                                                    "type": "BxGyDiscount",
                                                    "message": {
                                                        "en": "Buy 2 Get 1 Free!",
                                                        "es": "Compre 2 y obtenga 1 gratis"
                                                    },
                                                    "fields": [
                                                        {
                                                            "buy_qty": 2
                                                        },
                                                        {
                                                            "reward_qty": 1
                                                        },
                                                        {
                                                            "product_quota": 0
                                                        },
                                                        {
                                                            "gift_product": 2487
                                                        },
                                                        {
                                                            "max_allowed_items": 20
                                                        },
                                                        {
                                                            "maxAllowedUsage": 2
                                                        },
                                                        {
                                                            "resetFrequency": null
                                                        }
                                                    ]
                                                }
                                            ],
                                            "conditions": [
                                                {
                                                    "operator": "or",
                                                    "type": "ProductCategoryCond",
                                                    "id": "A01",
                                                    "name": "Snacks"
                                                },
                                                {
                                                    "operator": "or",
                                                    "type": "BrandCond",
                                                    "id": "566",
                                                    "name": "NAATU"
                                                },
                                                {
                                                    "operator": "and",
                                                    "type": "DateRange",
                                                    "starting": "01.06.2022",
                                                    "ending": "31.12.2022"
                                                }
                                            ]
                                        }
                                    ]
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/activity": {
            "get": {
                "tags": [
                    "Rewards"
                ],
                "summary": "Get activity events",
                "description": "Get list of reward activity events for logged in customer",
                "parameters": [
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "SiteId",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "limit",
                        "in": "query",
                        "required": false,
                        "description": "limit",
                        "schema": {
                            "type": "number",
                            "default": 25
                        }
                    },
                    {
                        "name": "offset",
                        "in": "query",
                        "required": false,
                        "description": "offset",
                        "schema": {
                            "type": "number",
                            "default": 0
                        }
                    },
                    {
                        "name": "orderBy",
                        "in": "query",
                        "description": "orderBy",
                        "required": false,
                        "schema": {
                            "type": "string",
                            "default": "desc"
                        }
                    },
                    {
                        "name": "order",
                        "in": "query",
                        "description": "order",
                        "required": false,
                        "schema": {
                            "type": "string",
                            "default": "desc"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "totalCount": 1,
                                    "data": [
                                        {
                                            "id": 1234,
                                            "name": "User Added",
                                            "eventType": "User",
                                            "eventObjectId": 4567,
                                            "identifier": "abc-123",
                                            "tags": "dog,cat,mouse",
                                            "metadata": "{\"key\":\"value\"}",
                                            "created": "2022-01-01 00:00:01"
                                        }
                                    ]
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/activity/history/{accountId}": {
            "get": {
                "tags": [
                    "Rewards"
                ],
                "summary": "Get reward activity history events",
                "description": "Get reward activity history events",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "Okta Access Token",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "Site Id",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "accountId",
                        "in": "path",
                        "description": "Store or Employee Id. Store Id = customer.contact.addresses[0].id. Employee Id = customer.id",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "type",
                        "in": "query",
                        "required": false,
                        "description": "History event type – all || activity || redemption || target",
                        "schema": {
                            "type": "string",
                            "default": "all"
                        }
                    },
                    {
                        "name": "date",
                        "in": "query",
                        "required": false,
                        "description": "Date from which to if start query on month basis then  query - Format YYYY-MM and else if start query on the particular date range filter then use the query - Format YYYY-MM-DD",
                        "schema": {
                            "type": "string",
                            "default": "Current date"
                        }
                    },
                    {
                        "name": "endDate",
                        "in": "query",
                        "required": false,
                        "description": "endDate from which to start query - Format YYYY-MM-DD for the particular date range filter",
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "limit",
                        "in": "query",
                        "required": false,
                        "description": "Number of history events to display",
                        "schema": {
                            "type": "number",
                            "default": 20,
                            "maximum": 100
                        }
                    },
                    {
                        "name": "offset",
                        "in": "query",
                        "required": false,
                        "description": "History event offset (pagination)",
                        "schema": {
                            "type": "number",
                            "default": 0
                        }
                    },
                    {
                        "name": "order",
                        "in": "query",
                        "required": false,
                        "description": "History event order – Events ordered by date – desc || asc",
                        "schema": {
                            "type": "string",
                            "default": "desc"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "totalCount": 3,
                                    "data": [
                                        {
                                            "id": "988",
                                            "type": "ACTIVITY",
                                            "points": "20",
                                            "pointStatus": "earned",
                                            "identifier": "reward-points",
                                            "tags": "abc123def",
                                            "metadata": {
                                                "UID": "e42a1f81"
                                            },
                                            "created": "2022-07-22 11:07:48"
                                        },
                                        {
                                            "id": 456,
                                            "type": "redemption",
                                            "points": 25,
                                            "pointStatus": "earned",
                                            "identifier": "def-456",
                                            "tags": "snacks,beverages",
                                            "metadata": [
                                                {
                                                    "title": "Reward product purchase"
                                                }
                                            ],
                                            "created": "2022-01-02 00:00:01"
                                        },
                                        {
                                            "id": 789,
                                            "type": "target",
                                            "points": 100,
                                            "pointStatus": "earned",
                                            "identifier": "xyz-789",
                                            "tags": "monthly",
                                            "metadata": [
                                                {
                                                    "title": "Monthly Sales Target"
                                                }
                                            ],
                                            "created": "2022-01-01 00:00:01"
                                        }
                                    ]
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/activity/points/{points}": {
            "get": {
                "tags": [
                    "Rewards"
                ],
                "summary": "Get activity points",
                "description": "Get estimated point value for activity",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "SiteId",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "points",
                        "in": "path",
                        "required": true,
                        "description": "Point Value",
                        "schema": {
                            "type": "integer"
                        }
                    },
                    {
                        "name": "activity",
                        "in": "query",
                        "required": false,
                        "description": "Activity Id or Activity Slug",
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "success": true,
                                    "pointEstimate": 4321
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/points/{accountId}": {
            "get": {
                "tags": [
                    "Rewards"
                ],
                "summary": "Reward Point Balance",
                "description": "Returns points for account specified by AccountId.",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "SiteId",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "accountId",
                        "in": "path",
                        "description": "Store or Employee Id. Store Id = customer.contact.addresses[0].id. Employee Id = customer.id",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "example": {
                                    "accountId": "T1",
                                    "points": 250,
                                    "lifetimePoints": 500
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/targets/{storeId}": {
            "get": {
                "tags": [
                    "Rewards"
                ],
                "summary": "Sales Targets",
                "description": "Returns list of active sales targets for a specific store",
                "parameters": [
                    {
                        "name": "okta-accesstoken",
                        "in": "header",
                        "required": true,
                        "description": "okta-accesstoken",
                        "schema": {
                            "type": "string",
                            "default": "eyJraWQiOiI2ZTdPNHpMdThqckMtZW5aUHhMVS0yZ2lIUW5xY3ZTMTRzQVNrXy1kbXJNIiwiYWxnIjoiUlMyNTYifQ"
                        }
                    },
                    {
                        "name": "SiteId",
                        "in": "header",
                        "required": true,
                        "description": "SiteId",
                        "schema": {
                            "type": "number",
                            "default": 7
                        }
                    },
                    {
                        "name": "storeId",
                        "in": "path",
                        "description": "Store ID",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "plainText",
                        "in": "query",
                        "required": false,
                        "description": "plainText",
                        "schema": {
                            "type": "boolean",
                            "default": false
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "examples": {
                                    "monthlyTarget": {
                                        "value": {
                                            "totalCount": 1,
                                            "data": [
                                                {
                                                    "salesTargetId": 345678,
                                                    "type": "Monthly Target",
                                                    "title": {
                                                        "en": "Sales Target Title",
                                                        "es": "Título del objetivo de ventas"
                                                    },
                                                    "excerpt": {
                                                        "en": "Sales target excerpt",
                                                        "es": "Extracto de objetivo de ventas"
                                                    },
                                                    "content": {
                                                        "en": "Sales target content",
                                                        "es": "Contenido objetivo de ventas"
                                                    },
                                                    "terms": {
                                                        "en": "Sales target terms",
                                                        "es": "Términos objetivo de ventas"
                                                    },
                                                    "category": {
                                                        "en": "Beverage",
                                                        "es": "Beverage"
                                                    },
                                                    "design": {
                                                        "textColor": "#ffffff",
                                                        "backgroundColor": "#ff0000",
                                                        "buttonTextColor": "#ffffff",
                                                        "buttonBackgroundColor": "#ff0000"
                                                    },
                                                    "images": [
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "background-image",
                                                            "optIn": false
                                                        },
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "foreground-image",
                                                            "optIn": true
                                                        }
                                                    ],
                                                    "url": "/optional/url/or/path/parameter",
                                                    "product": null,
                                                    "displayStartDate": "2022-08-28 00:00:00",
                                                    "displayEndDate": "2022-10-04 00:00:00",
                                                    "activeStartDate": "2022-09-01 00:00:00",
                                                    "activeEndDate": "2022-09-30 00:00:00",
                                                    "rewardDate": "2022-10-03 00:00:00",
                                                    "rewardsAwarded": false,
                                                    "requireOptIn": false,
                                                    "optedIn": false,
                                                    "status": "not_started",
                                                    "dollarSpend": 0,
                                                    "dollarTarget": 100,
                                                    "pointsToAward": 50,
                                                    "pointsAwarded": 0,
                                                    "pointDisplay": "cumulative",
                                                    "prerequisiteTargets": [
                                                        null
                                                    ],
                                                    "prerequisiteParent": 0
                                                }
                                            ]
                                        }
                                    },
                                    "productTarget": {
                                        "value": {
                                            "totalCount": 1,
                                            "data": [
                                                {
                                                    "salesTargetId": 345678,
                                                    "type": "Product Target",
                                                    "title": {
                                                        "en": "Sales Target Title",
                                                        "es": "Título del objetivo de ventas"
                                                    },
                                                    "excerpt": {
                                                        "en": "Sales target excerpt",
                                                        "es": "Extracto de objetivo de ventas"
                                                    },
                                                    "content": {
                                                        "en": "Sales target content",
                                                        "es": "Contenido objetivo de ventas"
                                                    },
                                                    "terms": {
                                                        "en": "Sales target terms",
                                                        "es": "Términos objetivo de ventas"
                                                    },
                                                    "category": {
                                                        "en": "Beverage",
                                                        "es": "Beverage"
                                                    },
                                                    "design": {
                                                        "textColor": "#ffffff",
                                                        "backgroundColor": "#ff0000",
                                                        "buttonTextColor": "#ffffff",
                                                        "buttonBackgroundColor": "#ff0000"
                                                    },
                                                    "images": [
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "background-image",
                                                            "optIn": false
                                                        },
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "foreground-image",
                                                            "optIn": true
                                                        }
                                                    ],
                                                    "url": "/optional/url/or/path/parameter",
                                                    "product": [
                                                        123,
                                                        456,
                                                        789
                                                    ],
                                                    "displayStartDate": "2022-08-28 00:00:00",
                                                    "displayEndDate": "2022-10-04 00:00:00",
                                                    "activeStartDate": "2022-09-01 00:00:00",
                                                    "activeEndDate": "2022-09-30 00:00:00",
                                                    "rewardDate": "2022-10-03 00:00:00",
                                                    "rewardsAwarded": false,
                                                    "requireOptIn": false,
                                                    "optedIn": false,
                                                    "status": "not_started",
                                                    "dollarSpend": 0,
                                                    "dollarTarget": 100,
                                                    "pointsToAward": 50,
                                                    "pointsAwarded": 0,
                                                    "pointDisplay": "cumulative",
                                                    "prerequisiteTargets": [
                                                        null
                                                    ],
                                                    "prerequisiteParent": 0
                                                }
                                            ]
                                        }
                                    },
                                    "productLayeredTarget": {
                                        "value": {
                                            "totalCount": 1,
                                            "data": [
                                                {
                                                    "salesTargetId": 456789,
                                                    "type": "Product Target Layered",
                                                    "title": {
                                                        "en": "Sales Target Title",
                                                        "es": "Título del objetivo de ventas"
                                                    },
                                                    "excerpt": {
                                                        "en": "Sales target excerpt",
                                                        "es": "Extracto de objetivo de ventas"
                                                    },
                                                    "content": {
                                                        "en": "Sales target content",
                                                        "es": "Contenido objetivo de ventas"
                                                    },
                                                    "terms": {
                                                        "en": "Sales target terms",
                                                        "es": "Términos objetivo de ventas"
                                                    },
                                                    "category": {
                                                        "en": "Snacks",
                                                        "es": "Snacks"
                                                    },
                                                    "design": {
                                                        "textColor": "#ffffff",
                                                        "backgroundColor": "#ff0000",
                                                        "buttonTextColor": "#ffffff",
                                                        "buttonBackgroundColor": "#ff0000"
                                                    },
                                                    "images": [
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "background-image",
                                                            "optIn": false
                                                        },
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "foreground-image",
                                                            "optIn": true
                                                        }
                                                    ],
                                                    "url": null,
                                                    "product": [
                                                        456,
                                                        789,
                                                        890
                                                    ],
                                                    "displayStartDate": "2022-08-28 00:00:00",
                                                    "displayEndDate": "2022-10-04 00:00:00",
                                                    "activeStartDate": "2022-09-01 00:00:00",
                                                    "activeEndDate": "2022-09-30 00:00:00",
                                                    "rewardDate": "2022-10-03 00:00:00",
                                                    "rewardsAwarded": false,
                                                    "requireOptIn": true,
                                                    "optedIn": true,
                                                    "status": "tier_achieved",
                                                    "dollarSpend": 125,
                                                    "tierTable": [
                                                        {
                                                            "dollarTarget": 100,
                                                            "pointsToAward": 150
                                                        },
                                                        {
                                                            "dollarTarget": 150,
                                                            "pointsToAward": 150
                                                        },
                                                        {
                                                            "dollarTarget": 300,
                                                            "pointsToAward": 300
                                                        }
                                                    ],
                                                    "tierAchieved": 1,
                                                    "pointsAwarded": 150,
                                                    "pointDisplay": "individual",
                                                    "prerequisiteTargets": [
                                                        null
                                                    ],
                                                    "prerequisiteParent": 0
                                                }
                                            ]
                                        }
                                    },
                                    "prerequisiteTarget": {
                                        "value": {
                                            "totalCount": 2,
                                            "data": [
                                                {
                                                    "salesTargetId": 567890,
                                                    "type": "Product Target Prerequisite",
                                                    "title": {
                                                        "en": "Sales Target Title",
                                                        "es": "Título del objetivo de ventas"
                                                    },
                                                    "excerpt": {
                                                        "en": "Sales target excerpt",
                                                        "es": "Extracto de objetivo de ventas"
                                                    },
                                                    "content": {
                                                        "en": "Sales target content",
                                                        "es": "Contenido objetivo de ventas"
                                                    },
                                                    "terms": {
                                                        "en": "Sales target terms",
                                                        "es": "Términos objetivo de ventas"
                                                    },
                                                    "category": {
                                                        "en": "Snacks",
                                                        "es": "Snacks"
                                                    },
                                                    "design": {
                                                        "textColor": "#ffffff",
                                                        "backgroundColor": "#ff0000",
                                                        "buttonTextColor": "#ffffff",
                                                        "buttonBackgroundColor": "#ff0000"
                                                    },
                                                    "images": [
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "background-image",
                                                            "optIn": false
                                                        },
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "foreground-image",
                                                            "optIn": true
                                                        }
                                                    ],
                                                    "url": "/optional/url/or/path/parameter",
                                                    "product": [
                                                        123,
                                                        456,
                                                        789
                                                    ],
                                                    "displayStartDate": "2022-08-28 00:00:00",
                                                    "displayEndDate": "2022-10-04 00:00:00",
                                                    "activeStartDate": "2022-09-01 00:00:00",
                                                    "activeEndDate": "2022-09-30 00:00:00",
                                                    "rewardDate": "2022-10-03 00:00:00",
                                                    "rewardsAwarded": false,
                                                    "requireOptIn": false,
                                                    "optedIn": false,
                                                    "status": "in_progress",
                                                    "dollarSpend": 150,
                                                    "dollarTarget": 1000,
                                                    "pointsToAward": 500,
                                                    "pointsAwarded": 0,
                                                    "pointDisplay": "individual",
                                                    "prerequisiteTargets": [
                                                        567891
                                                    ],
                                                    "prerequisiteParent": 0
                                                },
                                                {
                                                    "salesTargetId": 567891,
                                                    "type": "Product Target Prerequisite",
                                                    "title": {
                                                        "en": "Sales Target Title",
                                                        "es": "Título del objetivo de ventas"
                                                    },
                                                    "excerpt": {
                                                        "en": "Sales target excerpt",
                                                        "es": "Extracto de objetivo de ventas"
                                                    },
                                                    "content": {
                                                        "en": "Sales target content",
                                                        "es": "Contenido objetivo de ventas"
                                                    },
                                                    "terms": {
                                                        "en": "Sales target terms",
                                                        "es": "Términos objetivo de ventas"
                                                    },
                                                    "category": {
                                                        "en": "Beverages",
                                                        "es": "Beverages"
                                                    },
                                                    "design": {
                                                        "textColor": "#ffffff",
                                                        "backgroundColor": "#ff0000",
                                                        "buttonTextColor": "#ffffff",
                                                        "buttonBackgroundColor": "#ff0000"
                                                    },
                                                    "images": [
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "background-image",
                                                            "optIn": false
                                                        },
                                                        {
                                                            "image": "https://cepadmin2.spo.nonprod.wspdop.pepsico.com/_default_upload_bucket/BB_Originals_1920x650_V1_3.png",
                                                            "imageText": {
                                                                "en": "",
                                                                "es": ""
                                                            },
                                                            "id": "foreground-image",
                                                            "optIn": true
                                                        }
                                                    ],
                                                    "url": "/optional/url/or/path/parameter",
                                                    "product": [
                                                        123,
                                                        456,
                                                        789
                                                    ],
                                                    "displayStartDate": "2022-08-28 00:00:00",
                                                    "displayEndDate": "2022-10-04 00:00:00",
                                                    "activeStartDate": "2022-09-01 00:00:00",
                                                    "activeEndDate": "2022-09-30 00:00:00",
                                                    "rewardDate": "2022-10-03 00:00:00",
                                                    "rewardsAwarded": false,
                                                    "requireOptIn": false,
                                                    "optedIn": false,
                                                    "status": "in_progress",
                                                    "dollarSpend": 50,
                                                    "dollarTarget": 250,
                                                    "pointsToAward": 0,
                                                    "pointsAwarded": 0,
                                                    "pointDisplay": "individual",
                                                    "prerequisiteTargets": [
                                                        null
                                                    ],
                                                    "prerequisiteParent": 567890
                                                }
                                            ]
                                        }
                                    },
                                    "noTargets": {
                                        "value": {
                                            "totalCount": 0,
                                            "data": null
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
    },
    "components": {
        "schemas": {
            "Problem": {
                "type": "object",
                "properties": {
                    "logRef": {
                        "type": "string"
                    },
                    "message": {
                        "type": "string"
                    }
                }
            },
            "ErrorMessage": {
                "type": "object",
                "properties": {
                    "errors": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                }
            }
        }
    }
};