export const SwaggerB2CSpec = {
    "openapi": "3.0.1",
    "info": {
        "title": "User Registration",
        "version": "V.0.0.1"
    },
    "tags": [
        {
            "name": "Dashboard",
            "description": "These are Dashboard APIs"
        },
        {
            "name": "User",
            "description": "These are user APIs"
        }
    ],
    "paths": {
        "/add-user": {
            "post": {
                "tags": [
                    "User"
                ],
                "description": "This is add user API",
                "operationId": "user",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/User"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/User"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/partner-data": {
            "get": {
                "tags": [
                    "Dashboard"
                ],
                "description": "This is partner data API",
                "operationId": "partnerData",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object",
                                    "additionalProperties": {
                                        "type": "object"
                                    }
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/get-users": {
            "get": {
                "tags": [
                    "User"
                ],
                "description": "This is list all user API",
                "operationId": "getUsers",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "array",
                                    "items": {
                                        "$ref": "#/components/schemas/User"
                                    }
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/get-user/{id}": {
            "get": {
                "tags": [
                    "User"
                ],
                "description": "This is get user API",
                "operationId": "getUserById",
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "integer",
                            "format": "int64"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/User"
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/consumer-data": {
            "get": {
                "tags": [
                    "Dashboard"
                ],
                "description": "This is consumer data API",
                "operationId": "consumerData",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object",
                                    "additionalProperties": {
                                        "type": "object"
                                    }
                                }
                            }
                        }
                    },
                    "400": {
                        "description": "Bad Request",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "415": {
                        "description": "Unsupported Media Type",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/ErrorMessage"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "$ref": "#/components/schemas/Problem"
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    "components": {
        "schemas": {
            "Problem": {
                "type": "object",
                "properties": {
                    "logRef": {
                        "type": "string"
                    },
                    "message": {
                        "type": "string"
                    }
                }
            },
            "ErrorMessage": {
                "type": "object",
                "properties": {
                    "errors": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                }
            },
            "User": {
                "required": [
                    "firstName",
                    "lastName"
                ],
                "type": "object",
                "properties": {
                    "id": {
                        "type": "integer",
                        "format": "int64"
                    },
                    "firstName": {
                        "type": "string"
                    },
                    "lastName": {
                        "type": "string"
                    }
                },
                "xml": {
                    "name": "user"
                }
            }
        }
    }
};  