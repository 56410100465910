export const SwaggerUnifiedHostingSpec = {
    "openapi": "3.0.1",
    "info": {
        "title": "Unified Hosting Portal",
        "version": "V.0.0.1"
    },
    "tags": [
        {
            "name": "admin",
            "description": "These apis are supported admin related opeations."
        },
        {
            "name": "rendering-service",
            "description": "These apis are supported page rendering related operations."
        },
        {
            "name": "master-api",
            "description": "These apis are supported admin related operations."
        }
    ],
    "paths": {
        "/health": {
            "get": {
                "tags": [
                    "rendering-service"
                ],
                "operationId": "checkHealth",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/rendering-service/{appId}": {
            "get": {
                "tags": [
                    "rendering-service"
                ],
                "operationId": "getFileTemplateById_1",
                "parameters": [
                    {
                        "name": "appId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/i18n/getPageLabel": {
            "get": {
                "tags": [
                    "rendering-service"
                ],
                "operationId": "getMessages",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "pageName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/get-config/{appId}": {
            "get": {
                "tags": [
                    "rendering-service"
                ],
                "operationId": "getConsumerAppRedirectionByAppId",
                "parameters": [
                    {
                        "name": "appId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/save-consumner-app-basic-detail": {
            "post": {
                "tags": [
                    "admin"
                ],
                "operationId": "saveConsumerAppBasicDeail",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/ConsumerAppBasicDetail"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/ConsumerAppResponse"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/save-email-config": {
            "post": {
                "tags": [
                    "admin"
                ],
                "operationId": "saveEmailConfig",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/EmailConfig"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/BaseResonse"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/save-custom-app-config": {
            "post": {
                "tags": [
                    "admin"
                ],
                "operationId": "saveCustomAppConfig",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/CustomConfig"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/BaseResonse"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/uploadFile": {
            "post": {
                "tags": [
                    "admin"
                ],
                "operationId": "uploadFile",
                "parameters": [
                    {
                        "name": "appId",
                        "in": "query",
                        "required": true,
                        "description": "Consumer App Id",
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "fileType",
                        "in": "query",
                        "required": true,
                        "description": "The type of File that consumers wants to upload",
                        "schema": {
                            "type": "string",
                            "enum": [
                                "css",
                                "img",
                                "font"
                            ]
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "image/png": {
                            "schema": {
                                "required": [
                                    "file"
                                ],
                                "type": "object",
                                "properties": {
                                    "file": {
                                        "type": "string",
                                        "format": "binary"
                                    }
                                }
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/BaseResonse"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/getAllConsumerApp": {
            "post": {
                "tags": [
                    "admin"
                ],
                "operationId": "getAllConsumerApp",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "$ref": "#/components/schemas/FilterConsumerAppDetails"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "$ref": "#/components/schemas/AllConsumerAppResponse"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/getConsumerAppDetails/{appId}": {
            "get": {
                "tags": [
                    "admin"
                ],
                "operationId": "getConsumerAppByAppId",
                "parameters": [
                    {
                        "name": "appId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/i18n/getLanguage/{consumerAppId}": {
            "get": {
                "tags": [
                    "admin"
                ],
                "operationId": "getConsumerLanguage",
                "parameters": [
                    {
                        "name": "consumerAppId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "array",
                                    "items": {
                                        "type": "object",
                                        "properties": {
                                            "langCode": {
                                                "type": "string"
                                            },
                                            "langName": {
                                                "type": "string"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/i18n/updateLables/{consumerAppId}": {
            "post": {
                "tags": [
                    "admin"
                ],
                "operationId": "updateLable",
                "parameters": [
                    {
                        "name": "consumerAppId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "example": {
                                        "label_key_1": "value",
                                        "label_key_2": "value"
                                    }
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/check-exist-app-name": {
            "get": {
                "tags": [
                    "admin"
                ],
                "operationId": "checkExistAppName",
                "parameters": [
                    {
                        "name": "appName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/addNewLanguage": {
            "post": {
                "tags": [
                    "master-api"
                ],
                "operationId": "addNewLanguage",
                "parameters": [
                    {
                        "name": "langName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "langCode",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "required": [
                                    "file"
                                ],
                                "type": "object",
                                "properties": {
                                    "file": {
                                        "type": "string",
                                        "format": "binary"
                                    }
                                }
                            }
                        }
                    }
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/getMasterLables": {
            "get": {
                "tags": [
                    "master-api"
                ],
                "operationId": "getMessages_2",
                "parameters": [
                    {
                        "name": "lang",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "pageName",
                        "in": "query",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/getAllLanguage": {
            "get": {
                "tags": [
                    "master-api"
                ],
                "operationId": "getAllLanguage",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/admin/updateMasterLables": {
            "post": {
                "tags": [
                    "master-api"
                ],
                "operationId": "updateMasterLable",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    },
                    "500": {
                        "description": "Internal Server Error",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    "components": {
        "schemas": {
            "EmailConfig": {
                "type": "object",
                "properties": {
                    "appId": {
                        "type": "string"
                    },
                    "consumerAppName": {
                        "type": "string"
                    },
                    "emailFrom": {
                        "type": "string"
                    },
                    "emailFromName": {
                        "type": "string"
                    },
                    "logoURL": {
                        "type": "string"
                    },
                    "apiKey": {
                        "type": "string"
                    },
                    "isRead": {
                        "type": "boolean"
                    }
                }
            },
            "CustomConfig": {
                "type": "object",
                "properties": {
                    "appId": {
                        "type": "string"
                    },
                    "signInButtonColor": {
                        "type": "string"
                    },
                    "forgotPasswordColor": {
                        "type": "string"
                    },
                    "signInButtonBackgroundColor": {
                        "type": "string"
                    },
                    "inputColor": {
                        "type": "string"
                    },
                    "fontFamily": {
                        "type": "string"
                    }
                }
            },
            "ConsumerAppBasicDetail": {
                "type": "object",
                "properties": {
                    "appId": {
                        "type": "string"
                    },
                    "appName": {
                        "type": "string"
                    },
                    "consumerName": {
                        "type": "string"
                    },
                    "contactEmails": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    },
                    "appDescription": {
                        "type": "string"
                    },
                    "appRedirectionURL": {
                        "type": "string"
                    },
                    "createdDate": {
                        "type": "string"
                    },
                    "languages": {
                        "type": "array",
                        "items": {
                            "$ref": "#/components/schemas/Language"
                        }
                    }
                }
            },
            "Language": {
                "type": "object",
                "properties": {
                    "langCode": {
                        "type": "string"
                    },
                    "langName": {
                        "type": "string"
                    }
                }
            },
            "FilterConsumerAppDetails": {
                "type": "object",
                "properties": {
                    "pageNumber": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "pageSize": {
                        "type": "integer",
                        "format": "int32"
                    },
                    "sortColumn": {
                        "type": "string"
                    },
                    "sortType": {
                        "type": "string"
                    },
                    "roleAppName": {
                        "type": "array",
                        "items": {
                            "type": "string"
                        }
                    }
                }
            },
            "BaseResonse": {
                "type": "object",
                "properties": {
                    "responseStatus": {
                        "type": "string"
                    },
                    "messgae": {
                        "type": "string"
                    }
                }
            },
            "ConsumerAppResponse": {
                "type": "object",
                "properties": {
                    "responseStatus": {
                        "type": "string"
                    },
                    "messgae": {
                        "type": "string"
                    },
                    "appId": {
                        "type": "string",
                        "description": "This is a conumer App id"
                    }
                }
            },
            "AllConsumerAppResponse": {
                "type": "object",
                "properties": {
                    "data": {
                        "type": "array",
                        "items": {
                            "properties": {
                                "createdDate": {
                                    "type": "string"
                                },
                                "appName": {
                                    "type": "string"
                                },
                                "appRedirectionURL": {
                                    "type": "string"
                                },
                                "appId": {
                                    "type": "string"
                                },
                                "contactEmails": {
                                    "type": "array",
                                    "items": {
                                        "type": "string"
                                    }
                                },
                                "appDescription": {
                                    "type": "string"
                                },
                                "consumerName": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};