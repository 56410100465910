import { turkeyServers } from "./servers";


// API definition
const postOrderUpdate = {
    "post": {
        "tags": [
            "Order Payment Update From Apex"
        ],
        "summary": "Update CEP order",
        "description": "This API is used to update fields in the CEP order object.",
        "requestBody": {
            "content": {
                "application/json": {
                    "schema": {
                        "type": "object"
                    },
                    "examples": {
                        "normalPost": {
                            "summary": "POST",
                            "value": {
                                "orderId": "ord_644f8c49048a9",
                                "paymentTransactionId": "bf5a5761-5d8b-2dc2-63e2-9de56a455674",
                                "sfOrderId": "a2K7Y000000djv8UAA",
                                "PEPOrderId": "order1",
                                "status": "Success",
                                "orderItems": [
                                    {
                                        "rewardId": "123",
                                        "digitalCodes": [
                                            "code1"
                                        ]
                                    },
                                    {
                                        "rewardId": "18",
                                        "digitalCodes": [
                                            "code6",
                                            "code7"
                                        ]
                                    }
                                ],
                                "errors": null
                            }
                        }
                    }
                }
            }
        },
        "parameters": [
            {
                "name": "siteId",
                "in": "header",
                "required": true,
                "description": "siteId",
                "schema": {
                    "type": "string",
                    "default": "14"
                }
            },
            {
                "name": "x-api-key",
                "in": "query",
                "required": false,
                "description": "x-api-key",
                "schema": {
                    "type": "string"
                }
            },
        ],
        "responses": {
            "200": {
                "description": "OK",
                "content": {
                    "application/json": {
                        "examples": {
                            "success": {
                                "value": {
                                    "success": true,
                                    "msg": "Order updated successfully"
                                }
                            },
                            "failure": {
                                "value": {
                                    "success": false,
                                    "msg": "Order Number not exist | Please provide order number"
                                }
                            }
                        }
                    }
                }
            },
            "400": {
                "description": "Bad Request",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "415": {
                "description": "Unsupported Media Type",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/ErrorMessage"
                        }
                    }
                }
            },
            "500": {
                "description": "Internal Server Error",
                "content": {
                    "*/*": {
                        "schema": {
                            "$ref": "#/components/schemas/Problem"
                        }
                    }
                }
            }
        }
    }
}



// Component Schema
const problemSchema = {
    "type": "object",
    "properties": {
        "logRef": {
            "type": "string"
        },
        "message": {
            "type": "string"
        }
    }
};

const errorMessageSchema = {
    "type": "object",
    "properties": {
        "errors": {
            "type": "array",
            "items": {
                "type": "string"
            }
        }
    }
};

export const SwaggerB2BForApexSpec = {
    "openapi": "3.0.0",
    "info": {
        "title": "PepsiCo CEP B2B API",
        "description": "PepsiCo Consumer Engagement Platform B2B API for APEX",
        "version": "1.0.0"
    },
    "servers": turkeyServers,
    "paths": {
        "/order/update": postOrderUpdate,
    },
    "components": {
        "schemas": {
            "Problem": problemSchema,
            "ErrorMessage": errorMessageSchema
        }
    }
};