import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import {BrowserRouter} from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>,
  document.getElementById('root')
);


